import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: localStorage.getItem("language") && localStorage.getItem("language") !== (null || "null") ? localStorage.getItem("language") : "en",
  languages: [],
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setLanguages(state, action) {
      state.languages = action.payload;
    },
  },
});

export const { setLanguage, setLanguages } = languageSlice.actions;
const languageReducer = languageSlice.reducer;

export default languageReducer;
