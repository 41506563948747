import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  activeCategoryId: null,
  activeSubCategoryId: null,
  isShowFilterMenu: false,
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setIsShowFilterMenu(state, action) {
      state.isShowFilterMenu = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setActiveCategoryId(state, action) {
      state.activeCategoryId = action.payload;
    },
    setActiveSubCategoryId(state, action) {
      state.activeSubCategoryId = action.payload;
    },
  },
});

export const { setCategories, setActiveCategoryId, setActiveSubCategoryId, setIsShowFilterMenu } = categorySlice.actions;
const categoryReducer = categorySlice.reducer;

export default categoryReducer;
