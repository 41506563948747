import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import Auxiliary from "../../components/hoc/Auxiliary";
import { useTranslation } from "../../lang/TranslationProvider";


const TextComponent = ({ errroMessage }) => {
	const { translate } = useTranslation();
	return translate(errroMessage);
}

const getAlertType = (type, cb, error) => {
	if (!type) {
		cb.error(error);
	}
	switch (type) {
		case "info":
			return cb.info(error);
		case "success":
			return cb.success(error);
		case "warning":
			return cb.warning(error);
		case "error":
			return cb.error(error);
		default:
			break;
	}
};

class AlertService {
	static checkMessageType = respcode => {
		switch (respcode) {
			case 0:
				return "success";
			case 1:
				return "error";
			case 2:
				return "warning";
			case 3:
				return "info";
			case 4:
				return "warning";
			default:
				return "error";
		}
	};

	static alert = (type, error) => {
		const respMessage =
			typeof error === "object" ? error.message || error.respmess : error;
		if (!respMessage) {
			return false;
		}
		if (respMessage.includes("TR_")) {
			getAlertType(type, toast, <TextComponent errroMessage={respMessage} />);
		} else {
			getAlertType(type, toast, respMessage);
		}
	};

	static alertConfirm = (title, message, yes, no) => {
		return new Promise((resolve, reject) => {
			confirmAlert({
				customUI: ({ onClose }) => {
					return (
						<div className="mindalay--modal-window">
							<div className="mindalay--modal-window-wrapper">
								<div className="mindalay--modal">
									<div className="d-flex align-items-start">
										{/* <img src={warningSvg} alt="/" /> */}
										<div className="d-block">
											{title ? (
												<Auxiliary>
													<strong>{title}</strong>
												</Auxiliary>
											) : null}
											{message ? <p className="mt-3">{message}</p> : null}
										</div>
									</div>
									<hr />
									<div className="d-flex mt-3 justify-content-end">
										<button
											className="mindalay--btn mindalay--btn-dark mx-1 yes"
											onClick={() => {
												resolve();
												onClose();
											}}>
											{yes}
										</button>
										<button
											className="mindalay--btn mindalay--btn-secondary-outline mx-1 no"
											onClick={() => {
												reject();
												onClose();
											}}>
											{no}
										</button>
									</div>
								</div>
							</div>
						</div>
					);
				},
			});
		});
	};

}

export default AlertService;
