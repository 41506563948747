import React, { useEffect, useRef, useState } from "react";
import MainService from "./../helpers/services/mainService";
import { ApiService } from "./../helpers/services/apiSrevice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { setActiveCategoryId, setActiveSubCategoryId, setCategories, setIsShowFilterMenu } from "../store/reducers/categoryReducer";
import { useTranslation } from "../lang/TranslationProvider";
import { addProductToCart, addProductToWishList, removeProductFromWishList } from "../store/reducers/productReducer";

export default function Store() {

	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { categoryId } = useParams();
	const { categories, activeCategoryId, activeSubCategoryId } = useSelector(state => state.category);
	const { wishListProducts, cartProducts } = useSelector(state => state.product);

	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const [sortingTypeId, setSortingTypeId] = useState(0);

	const [pagination, setPagination] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);

	const [artworks, setArtworks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pageTitle, setPageTitle] = useState("");
	const [sortingType, setSortingType] = useState(null);

	const targetRef = useRef(null);

	useEffect(() => {
		const findedCategory = findNamesById(categories, categoryId);
		if (findedCategory) {
			setPageTitle(MainService.getValueByLanguageId(findedCategory.names, languageId));
		} else {
			setPageTitle("");
		}
	}, [categories, categoryId, languageId])

	useEffect(() => {
		const handleIntersect = (entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					getArtworks(currentPage + 1);
				}
			});
		};
		const observer = new IntersectionObserver(handleIntersect, {
			root: null,
			rootMargin: '0px',
			threshold: 1.0,
		});
		if (targetRef.current) {
			observer.observe(targetRef.current);
		}
		return () => {
			if (targetRef.current) {
				observer.unobserve(targetRef.current);
			}
		};
	}, [currentPage, pagination, isLoading]);

	useEffect(() => {
		getArtworks(1);
	}, [categoryId, sortingTypeId])

	useEffect(() => {
		getAllCategories();
		getLibraryData();
	}, [])

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	const getLibraryData = () => {
		MainService.callApi({
			api: ApiService.getLibraryData,
			data: "sorting",
			cb: (response) => {
				if (response && response.data) {
					setSortingType(response.data);
				}
			},
		});
	};

	const handleSortChange = (event) => {
		const selectedId = parseInt(event.target.value, 10);
		setSortingTypeId(selectedId);
	};

	const getArtworks = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
		}
		if (categoryId) {
			data.categoryId = categoryId;
		}
		if (sortingTypeId) {
			data.sortingTypeId = sortingTypeId;
		}

		setIsLoading(true);
		MainService.callApi({
			api: ApiService.getArtworks,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setPagination(data.pagination)
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setArtworks([...artworks, ...data.pagedData]);
						} else {
							setArtworks(data.pagedData);
						}
					} else {
						setArtworks([]);
					}
				}
				setIsLoading(false);
				setCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	const getAllCategories = () => {
		MainService.callApi({
			api: ApiService.getAllCategories,
			data: {},
			cb: (response) => {
				if (response && response.data) {
					dispatch(setCategories(response.data))
				}
			},
		});
	}

	const setCategoryIds = (categoryId = null, subCategoryId = null) => {
		if (activeCategoryId === categoryId && !subCategoryId) {
			dispatch(setActiveCategoryId(null));
			return false;
		}
		dispatch(setActiveCategoryId(categoryId));
		dispatch(setActiveSubCategoryId(subCategoryId));
	}

	const findNamesById = (arr = [], targetId = null) => {
		if (!arr.length || !targetId || !languageId) {
			return false;
		}
		for (const item of arr) {
			if (item.id === +targetId) {
				if (item) {
					return item;
				}
			}
			if (item.childCategories && item.childCategories.length > 0) {
				const result = findNamesById(item.childCategories, +targetId);
				if (result) {
					return result;
				}
			}
		}
		return null;
	}

	return (
		<div className="cont">
			<div className="catalog">
				<div className="catalog-menu pull-left">
					{
						categories && categories.length ?
							<ul>
								{
									categories.map((item) => {
										return <li key={item.id} className={activeCategoryId === item.id ? "open" : ""}>
											<Link
												to={`/store/${item.id}`}
												className={activeCategoryId === item.id ? "active" : ""}
												onClick={() => setCategoryIds(item.id)}
											>
												{MainService.getValueByLanguageId(item.names, languageId)} {item.count ? `(${item.count})` : null}
											</Link>
											{
												item.childCategories && item.childCategories.length && activeCategoryId === item.id ?
													<ul>
														{
															item.childCategories.map((el) => {
																return <li key={el.id}>
																	<Link
																		to={`/store/${el.id}`}
																		className={activeSubCategoryId === el.id ? "active" : ""}
																		onClick={() => setCategoryIds(item.id, el.id)}
																	>
																		{MainService.getValueByLanguageId(el.names, languageId)} {el.count ? `(${el.count})` : null}
																	</Link>
																</li>
															})
														}
													</ul>
													: null
											}
										</li>
									})
								}
							</ul>
							: null
					}
					<Link
						className="btn btn-no-str"
						to="/store"
						onClick={() => {
							setSortingTypeId(0);
							dispatch(setActiveCategoryId(null));
							dispatch(setActiveSubCategoryId(null))
						}}
					>
						{translate("TR_RESET_FILTERS")}
					</Link>
				</div>
				<div className="catalog-list pull-right">
					<h1 className="pull-left">
						{
							pageTitle ? pageTitle : translate("TR_ONLINE_STORE")
						}
					</h1>
					<form className="pull-right">
						{
							sortingType ? (
								<select
									className="pull-right"
									name="sort"
									onChange={handleSortChange}
									value={sortingTypeId}
								>
									<option defaultValue={true} value={0}>
										{translate(sortingType.displayName)}
									</option>
									{
										sortingType.libraryDatas && sortingType.libraryDatas.length > 0 ? (
											sortingType.libraryDatas.map((item) => (
												<option key={item.id} value={item.id}>
													{translate(item.displayName) || "TR_SORTING"}
												</option>
											))
										) : null
									}
								</select>
							) : null
						}
					</form>
					<img
						className="filter-btn mobile"
						src="images/filter-btn.png"
						onClick={() => {
							dispatch(setIsShowFilterMenu(true))
						}}
					/>

					<div className="clear"></div>
					<div className="product-list">
						<div className="list" style={{ display: "flex", flexWrap: "wrap" }}>
							{artworks && artworks.length ?
								artworks.map((item, index) => {
									const isInWishList = wishListProducts.some(el => el.id === item.id);
									const isInShoppingCart = cartProducts.some(el => el.id === item.id);
									return (
										<div className="item last" key={index}>
											<div
												className={`fav to-fav ${isInWishList ? 'remove-fav' : ''}`}
												onClick={() => {
													if (isInWishList) {
														dispatch(removeProductFromWishList(item));
													} else {
														dispatch(addProductToWishList(item));
													}
												}}
											/>
											<Link className="image" to={`/artwork/${item.id}`}>
												<div className="image-cont">
													<img
														src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"}
														style={{ height: "100%" }}
													/>
												</div>
											</Link>
											{
												// item.price || item.oldPrice ?
													<Link
														className="cart add-to-cart"
														to={`${isInShoppingCart ? "/cart" : "#"}`}
														onClick={() => {
															if (!isInShoppingCart) {
																dispatch(addProductToCart(item));
																alert(translate("TR_ADDED_TO_CART"))
															}
														}}
													>
													</Link>
													// : null
											}
											<Link className="name" to={`/artwork/${item.id}`}>
												{MainService.getValueByLanguageId(item.names, languageId)}
											</Link>
											<span>
												{MainService.getValueByLanguageId(item.artistNames, languageId)}
											</span>
											<div className="line"></div>
											<b className="price">
												{item.price ? `${item.price} ₽` : translate("TR_PRICE_ON_REQUEST")}
											</b>
											<Link className="view" to={`/artwork/${item.id}`}>{translate("TR_VIEW")}</Link>
											<div className="clear"></div>
										</div>
									);
								})
								: null
							}



							<div className="clear"></div>
						</div>
					</div>

					{
						pagination && pagination.totalPages > currentPage && !isLoading && artworks.length ?
							<div ref={targetRef} style={{ height: '10px', width: "100%" }} />
							: null
					}
					{
						isLoading ?
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<span className="custom-loader"></span>
							</div>
							: null
					}
				</div>
				<div className="clear"></div>
			</div>

		</div>

	)

}
