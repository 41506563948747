import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import languageReducer from "./reducers/languageReducer";
import translationReducer from "./reducers/translationReducer";
import productReducer from "./reducers/productReducer";
import categoryReducer from "./reducers/categoryReducer";
import artistReducer from "./reducers/artistReducer";

const rootReducer = combineReducers({
	user: userReducer,
	language: languageReducer,
	translation: translationReducer,
	product: productReducer,
	category: categoryReducer,
	artist: artistReducer,
});

const index = configureStore({
	reducer: rootReducer,
});

export default index;
