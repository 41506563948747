import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { useTranslation } from "../lang/TranslationProvider";
import { ApiService } from "../helpers/services/apiSrevice";
import { Table } from "react-bootstrap";
import moment from "moment";

export default function OrderView() {

	const targetRef = useRef(null);
	const { itemId } = useParams();
	const { translate } = useTranslation();
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);

	const [orderData, setOrderData] = useState(null);
	const [orders, setOrders] = useState([]);
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!user) {
			navigate("/")
		}
	}, [user])

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getOrderById();
	}, [itemId]);

	const getOrderById = () => {
		if (!itemId) { return false; }
		setIsLoading(true)
		MainService.callApi({
			api: ApiService.getOrderById,
			data: itemId,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					setOrderData(data);
				}
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}


	return (
		<div className="cont">
			<Link className="back-btn" to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
			<div className="catalog">
				<div className="catalog-list pull-right" style={{ width: "100%" }}>
					<h1 className="pull-left">{translate("TR_ORDER")}</h1>
					<div className="clear"></div>
					<div style={{ border: "1px solid #beab8f", padding: "20px", marginBottom: "50px", display: "flex", justifyContent: "space-between", fontWeight:"600" }}>
						<p style={{ fontSize: "20px" }}>{translate("TR_TOTAL")}</p>
						<p style={{ fontSize: "20px" }}>{orderData?.totalPrice}₽</p>
					</div>
					{
						languageId ?
							<div className="product-list">
								{
									isLoading ?
										<div style={{
											display: "flex",
											justifyContent: "center"
										}}>
											<span className="custom-loader"></span>
										</div>
										: <div className="list" style={{ display: "flex", flexWrap: "wrap" }}>
											{orderData && orderData.artworks && orderData.artworks.length ?
												orderData.artworks.map((item, index) => {
													return (
														<div className="item last" key={index}>
															<Link className="image" to={`/artwork/${item.id}`}>
																<div className="image-cont">
																	<img
																		src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"}
																		style={{ height: "100%" }}
																	/>
																</div>
															</Link>
															<Link className="name" to={`/artwork/${item.id}`}>
																{MainService.getValueByLanguageId(item.names, languageId)}
															</Link>
															<div>
																<b className="price" style={{ float: "none" }}>
																	{item.price ? `${item.price} ₽` : translate("TR_PRICE_ON_REQUEST")}
																</b>
															</div>
															<span style={{ marginTop: "10px", display: "block" }}>
																{MainService.getValueByLanguageId(item.artistNames, languageId)}
															</span>
															<div style={{ marginTop: "10px" }}>
																<p style={{ display: "flex" }}>
																	{
																		item.materials && item.materials.length ?
																			item.materials.map((el, idx) => {
																				return <span key={idx}>{translate(el)}{`${item.materials.length === idx + 1 ? "" : ","}`}</span>
																			})
																			: null
																	}
																</p>
																<p style={{ marginTop: "10px" }}>{item.size} {translate("TR_SM")}, {moment(item.productionDate).format("YYYY")} {translate("TR_YEAR")}</p>
															</div>
															<div style={{ display: "flex", gap: "20px", alignItems: "center", marginTop: "10px" }}>
																<span>{translate("TR_GIFT")}:</span>
																<span>{translate(item.isGift ? "TR_YES" : "TR_NO")}</span>
															</div>
															<p style={{ marginTop: "10px", display: "block", fontSize: "13px", lineHeight: "15px" }}>{item.giftMessage}</p>
															<div className="line"></div>
															<Link className="view" to={`/artwork/${item.id}`}>{translate("TR_VIEW")}</Link>
															<div className="clear"></div>
														</div>
													);
												})
												: null
											}
											<div className="clear"></div>
										</div>
								}

							</div>
							: null
					}


				</div>
				<div className="clear"></div>
			</div>


		</div>
	)

}
