import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";

export default function MotionDiv({ children, initial, animate, exit, ready = true, duration }) {
    const ref = useRef();
    const [isVisible, setIsVisible] = useState(false);

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting && ready) {
                setIsVisible(true);
                observer.disconnect(); // Остановить наблюдение после первого пересечения
            }
        },
        {
            threshold: 0.01, // Процент видимости элемента для запуска анимации
        }
    );

    useEffect(() => {
        if (ref.current && observer) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ready, observer, ref, children]);

    return (
        <motion.div
            ref={ref}
            initial={initial}
            animate={isVisible ? animate : initial}
            exit={exit}
            transition={{ duration: duration ? duration : 1 }}
        >
            {children}
        </motion.div>
    );
};



// import React, { useRef, useEffect, useState } from "react";
// import { motion } from "framer-motion";

// export default function MotionDiv({ children, initial, animate, exit, ready = true, duration }) {
//     const ref = useRef();
//     const [isVisible, setIsVisible] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 console.log(`IntersectionObserver entry: `, entry); // Логгирование entry
//                 if (entry.isIntersecting && ready) {
//                     setIsVisible(true);
//                     observer.disconnect(); // Остановить наблюдение после первого пересечения
//                 }
//             },
//             {
//                 threshold: 0.1, // Процент видимости элемента для запуска анимации
//             }
//         );

//         if (ref.current) {
//             observer.observe(ref.current);
//         }

//         return () => {
//             if (ref.current) {
//                 observer.unobserve(ref.current);
//             }
//         };
//     }, [ready]);

//     useEffect(() => {
//         console.log(`isVisible state: `, isVisible); // Логгирование состояния isVisible
//     }, [isVisible]);

//     return (
//         <motion.div
//             ref={ref}
//             initial={initial}
//             animate={isVisible ? animate : initial}
//             exit={exit}
//             transition={{ duration: duration ? duration : 1 }}
//         >
//             {children}
//         </motion.div>
//     );
// };


// import React, { useRef, useEffect, useState } from "react";
// import { motion } from "framer-motion";

// export default function MotionDiv({ children, initial, animate, exit, ready = true, duration }) {
//     const ref = useRef();
//     const [isVisible, setIsVisible] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 console.log(`IntersectionObserver entry: `, entry); // Логгирование entry
//                 if (entry.isIntersecting && ready) {
//                     setIsVisible(true);
//                     observer.disconnect(); // Остановить наблюдение после первого пересечения
//                 }
//             },
//             {
//                 threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1], // Несколько значений threshold для тестирования
//             }
//         );

//         if (ref.current) {
//             observer.observe(ref.current);
//         }

//         return () => {
//             if (ref.current) {
//                 observer.unobserve(ref.current);
//             }
//         };
//     }, [ready]);

//     useEffect(() => {
//         console.log(`isVisible state: `, isVisible); // Логгирование состояния isVisible
//     }, [isVisible]);

//     return (
//         <motion.div
//             ref={ref}
//             initial={initial}
//             animate={isVisible ? animate : initial}
//             exit={exit}
//             transition={{ duration: duration ? duration : 1 }}
//             style={{ border: '1px solid red' }} // Временная граница для видимости элемента
//         >
//             {children}
//         </motion.div>
//     );
// };

