import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { useTranslation } from "../lang/TranslationProvider";
import { useDispatch, useSelector } from "react-redux";
import { setArtistData } from "../store/reducers/artistReducer";
import parse from 'html-react-parser';
import moment from "moment";
import LightboxComponent from "../components/lightbox-component/LightboxComponent";


export default function Artist() {

	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { artistId } = useParams();
	const { artistData } = useSelector(state => state.artist);
	const [artistWorksSwiperRef, setArtistWorksSwiperRef] = useState(null);
	const [exhibitions, setExhibitions] = useState([]);
	const [works, setWorks] = useState([]);

	const [categoryId, setCategoryId] = useState(null);

	const [worksPagination, setWorksPagination] = useState(null);
	const [worksCurrentPage, setWorksCurrentPage] = useState(1);

	const [exhibitionsPagination, setExhibitionsPagination] = useState(null);
	const [exhibitionsCurrentPage, setExhibitionsCurrentPage] = useState(1);

	const [isLoading, setIsLoading] = useState(false);

	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);

	const [pageContent, setPageContent] = useState(null);
	const [images, setImages] = useState([]);

	const [artistCategories, setArtistCategories] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [filePath, setFilePath] = useState("");

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, [])

	useEffect(() => {
		if (artistData && artistData.imagePaths && artistData.imagePaths.length) {
			setImages(artistData.imagePaths.filter(item => !item.isMain));
		} else {
			setImages([]);
		}
	}, [artistData])

	useEffect(() => {
		getArtworksByArtistId(1);
	}, [categoryId])

	useEffect(() => {
		getArtistById();
		getPageContentByKeyName();
		getArtistCategories();
		getExhibitionsByArtistId(1);
		return () => {
			dispatch(setArtistData(null))
		}
	}, [artistId])

	const getArtistCategories = () => {
		MainService.callApi({
			api: ApiService.getArtistCategories,
			data: artistId,
			cb: response => {
				if (response && response.data) {
					setArtistCategories(response.data);
				}
			},
		});
	}
	const getPageContentByKeyName = () => {
		MainService.callApi({
			api: ApiService.getPageContentByKeyName,
			data: "KEY_ARTIST_PAGE_EXHIBITIONS",
			cb: response => {
				if (response && response.data) {
					setPageContent(response.data);
				}
			},
		});
	}

	const getArtistById = () => {
		if (!artistId) {
			return false;
		}
		MainService.callApi({
			api: ApiService.getArtistById,
			data: artistId,
			cb: (response) => {
				if (response && response.data) {
					dispatch(setArtistData(response.data))
				}
			},
		});
	};

	const getExhibitionsByArtistId = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
			artistId,
		}
		MainService.callApi({
			api: ApiService.getExhibitionsByArtistId,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setExhibitionsPagination(data.pagination);
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setExhibitions([...exhibitions, ...data.pagedData]);
						} else {
							setExhibitions(data.pagedData);
						}
					} else {
						setExhibitions([]);
					}
				}
				setIsLoading(false);
				setExhibitionsCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	const getArtworksByArtistId = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
			artistId,
			categoryId,
		}
		MainService.callApi({
			api: ApiService.getArtworksByArtistId,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setWorksPagination(data.pagination);
					}
					if (data.pagedData && data.pagedData.length) {
						if (currentPage > 1) {
							setWorks([...works, ...data.pagedData]);
						} else {
							setWorks(data.pagedData);
						}
					} else {
						setWorks([]);
					}
				}
				setIsLoading(false);
				setWorksCurrentPage(currentPage);
			},
			_finally: () => {
				setIsLoading(false);
			},
		});
	}

	const scrollToCurrentDivPosition = (id, offset = 0) => {
		const targetElement = document.getElementById(id);
		if (targetElement) {
			const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
			const offsetPosition = elementPosition + offset;

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth'
			});
		}
	};

	const sliderRef = useRef(null);
	const [activeImageIndex, setActiveImageIndex] = useState(1);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current || activeImageIndex === 1) return;
		sliderRef.current.swiper.slidePrev();
		setActiveImageIndex(activeImageIndex - 1)
	}, [activeImageIndex]);

	const handleNext = useCallback(() => {
		if (!sliderRef.current || activeImageIndex === images.length) return;
		sliderRef.current.swiper.slideNext();
		setActiveImageIndex(activeImageIndex + 1)
	}, [activeImageIndex]);

	const handleSlideChange = useCallback(() => {
		if (!sliderRef.current) return;
		setActiveImageIndex(sliderRef.current.swiper.realIndex + 1);
	}, []);

	if (!artistData) {
		return <div style={{
			display: "flex",
			justifyContent: "center"
		}}>
			<span className="custom-loader"></span>
		</div>;
	}

	return (
		<div className="cont">
			<LightboxComponent
				isOpen={isOpen}
				filePath={filePath}
				onHide={() => {
					setIsOpen(false);
					setFilePath("");
				}}
			/>
			<Link className="back-btn" to="/artists">{translate("TR_BACK_TO_ALL_ARTISTS")}</Link>
			{
				artistData ?
					<div className="main-page-bunner artist-page-bunner">
						<div className="image pull-left">
							<div className="image-cont">
								{
									artistData.imagePaths && artistData.imagePaths.length ?
										artistData.imagePaths.map((item, index) => {
											if (item.isMain) {
												return <img key={index} src={item.filePath} alt="image" style={{ minHeight: "100%", maxHeight: "500px" }} />
											}
										})
										: <img src="images/our-images/no-image.png" style={{ height: "100%" }} />
								}

							</div>
						</div>
						<div className="text pull-right">
							{
								artistData.artworks && artistData.artworks.length ?
									<div className="work-list desktop">
										<div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slidePrev()}></div>
										<div className="list">
											<Swiper
												slidesPerView={1}
												spaceBetween={10}
												loop
												onSwiper={(ref) => setArtistWorksSwiperRef(ref)}
												updateOnWindowResize
												breakpoints={{
													1024: {
														slidesPerView: 5,
														spaceBetween: 0,
													},
												}}
												className="mySwiper"
											>
												{
													artistData.artworks.map((item, index) => {
														return <SwiperSlide key={index}>
															<Link
																className="work-image fancybox"
																to="#"
																onClick={() => {
																	setIsOpen(true);
																	setFilePath(item.imagePath ? item.imagePath : "images/our-images/no-image.png")
																}}
															>
																<img
																	src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"}
																	style={{ height: "100%" }}
																	alt="image"
																/>
															</Link>
														</SwiperSlide>
													})
												}

											</Swiper>
										</div>
										<div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slideNext()}></div>
										<div className="clear"></div>
									</div>
									: null
							}
							<span className="label">
								<span>{translate("TR_ARTIST")}</span>
								/{translate("TR_WORKS")}
							</span>
							<div className="line"></div>
							<h1 className="name">{MainService.getValueByLanguageId(artistData.names, languageId)}</h1>
							<div className="text-block">
								<p>
									{MainService.getValueByLanguageId(artistData.shortBios, languageId)}
								</p>
							</div>
							<div className="tags tags-artist">
								<div className="tabs-list">
									<Link to="#" onClick={() => scrollToCurrentDivPosition("tab-1", -200)}>{translate("TR_WORKS")}</Link>
									<Link to="#" onClick={() => scrollToCurrentDivPosition("tab-2", -200)}>{translate("TR_ABOUT")}</Link>
									<Link to="#" onClick={() => scrollToCurrentDivPosition("tab-3", -180)}>{translate("TR_EXHIBITIONS")}</Link>
									<div className="clear"></div>
								</div>
							</div>
							<div className="links">
								<div className="links-list">
									{
										artistData.cataloguePath ?
											<a className="pdf" href={artistData.cataloguePath} target="_blank">
												{translate("TR_CATALOGUE")}
											</a>
											: null
									}
									{
										artistData.bookPath ?
											<a className="pdf" href={artistData.bookPath} target="_blank">
												{translate("TR_BOOK")}
											</a>
											: null
									}
									{
										artistData.webSite ?
											<a href={artistData.webSite} target="_blank">
												{translate("TR_WEBSITE")}
											</a>
											: null
									}
									{
										artistData.filePath || artistData.filmLink ?
											<a href={artistData.filePath ? artistData.filePath : artistData.filmLink} target="_blank">
												{translate("TR_FILM")}
											</a>
											: null
									}
									<div className="clear"></div>
								</div>
							</div>
						</div>
						<div className="clear"></div>

						{
							artistData.artworks && artistData.artworks.length ?
								<div className="work-list mobile">
									<div className="prev d-block" style={{ cursor: "pointer" }}
										onClick={() => artistWorksSwiperRef?.slidePrev()}
									/>

									<div className="list" mobile_width="608">
										<Swiper
											slidesPerView={1}
											spaceBetween={10}
											loop
											onSwiper={(ref) => setArtistWorksSwiperRef(ref)}
											updateOnWindowResize
											breakpoints={{
												1024: {
													slidesPerView: 5,
													spaceBetween: 0,
												},
											}}
											className="mySwiper"
										>
											{
												artistData.artworks.map((item, index) => {
													return <SwiperSlide key={index}>
														<Link className="work-image fancybox"
															to="#"
															onClick={() => {
																setIsOpen(true);
																setFilePath(item.imagePath ? item.imagePath : "images/our-images/no-image.png")
															}}
														>
															<img
																src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"}
																style={{ height: "100%" }}
																alt="image"
															/>
														</Link>
													</SwiperSlide>
												})
											}

										</Swiper>
									</div>
									<div
										className="next d-block"
										style={{ cursor: "pointer" }}
										onClick={() => artistWorksSwiperRef?.slideNext()}
									/>
									<div className="clear"></div>
								</div>
								: null
						}
					</div>
					: null
			}
			<div className="big-catalog big-catalog-autor" id="tab-1">
				<div className="cat-list">
					<div className="list">
						<div className="list-cont">
							<Link className={!categoryId ? "active" : ""} to={`#`}
								onClick={() => setCategoryId(null)}
							>
								{translate("TR_ALL")}
							</Link>
							{
								artistCategories && artistCategories.length ?
									artistCategories.map((item, index) => {
										return <Link
											key={index}
											className={+categoryId === item.id ? "active" : ""} to={`#`}
											onClick={() => setCategoryId(item.id)}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
									})
									: null
							}
							<div className="clear"></div>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				<div className="work-list work-list-no-shadow">
					<div className="work-list-cont">
						{
							works && works.length ?
								works.map((item, index) => {
									return <div key={index} className="work-item" rel="24">
										<Link className="image" to={`/artwork/${item.id}`}>
											<div className="image-cont">
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} />
											</div>
										</Link>
										<Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
										<div className="line"></div>
										<div>
											<p style={{ display: "flex" }}>
												{
													item.materials && item.materials.length ?
														item.materials.map((el, idx) => {
															return <span key={idx}>{translate(el)}{`${item.materials.length === idx + 1 ? "" : ","}`}</span>
														})
														: null

												}
											</p>
											<p>{item.size} {translate("TR_SM")}, {moment(item.productionDate).format("YYYY")} {translate("TR_YEAR")}</p>
										</div>
									</div>
								})
								: null
						}

						<div className="clear"></div>
					</div>
				</div>

				{
					worksPagination && worksPagination.totalPages > worksCurrentPage && !isLoading && works.length ?
						<center style={{ display: "block" }}>
							<Link
								className='btn btn-no-str'
								to='#'
								style={{ width: "150px", marginTop: "50px" }}
								onClick={() => getArtworksByArtistId(worksCurrentPage + 1)}
							>
								{translate("TR_SHOW_MORE")}
							</Link>
						</center>
						: null
				}
				{
					isLoading ?
						<div style={{
							display: "flex",
							justifyContent: "center"
						}}>
							<span className="custom-loader"></span>
						</div>
						: null
				}

			</div>
			{
				artistData ?
					<div className="about-artist-block" id="tab-2">
						<div className="about-artist-text pull-left">
							<span className="label">
								<span>{translate("TR_ARTIST")}</span>
								/{translate("TR_ABOUT")}
							</span>
							<div className="line"></div>
							<div className="name">{MainService.getValueByLanguageId(artistData.names, languageId)}</div>
							<div className="text">
								{parse(MainService.getValueByLanguageId(artistData.longBios, languageId))}
							</div>
						</div>
						<div className="about-image pull-right">
							{
								images && images.length && images.filter(el => !el.isMain).length ?
									<div className="image">
										<div className="image-cont atrist-images">
											<Swiper
												ref={sliderRef}
												onSlideChange={handleSlideChange}
											>
												{
													images.filter(el => !el.isMain).map((item, index) => {
														return <SwiperSlide key={index}>
															<img src={item.filePath ? item.filePath : "images/our-images/no-image.png"} />
														</SwiperSlide>
													})
												}
											</Swiper>
										</div>
										<div className="nav">
											<div className="prev" onClick={handlePrev}></div>
											<span size="32">{activeImageIndex}/{images.length}</span>
											<div className="next" onClick={handleNext}></div>
											<div className="clear"></div>
										</div>
									</div>
									: null
							}
						</div>
						<div className="clear"></div>
					</div>
					: null
			}
			<div className="exhibitions-list" id="tab-3">
				{
					pageContent ?
						<React.Fragment>
							<div className="title mobile">{MainService.getValueByLanguageId(pageContent.titles, languageId)}</div>
							<div className="line mobile"></div>
							<div className="cover mobile">
								<div className="image">
									<div className="image-cont">
										{
											pageContent.imagePaths && pageContent.imagePaths.length ?
												<img src={pageContent.imagePaths[0]} alt="image" style={{ height: "100%" }} />
												: <img src="images/our-images/no-image.png" style={{ height: "100%" }} />

										}
									</div>
								</div>
							</div>
						</React.Fragment>
						: null
				}
				<div className="exhibition-item-cont mobile">
					<div className="exhibition-item-cont-block">
						{
							exhibitions && exhibitions.length ?
								exhibitions.map((item, index) => {
									return <div key={index} className="exhibition-item">
										<div className="date">
											<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
											<span></span>
											<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/exhibition/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/exhibition/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
									</div>
								})
								: null
						}
						<div className="clear"></div>
					</div>
				</div>
				<div className="column pull-left desktop">
					<div className="cover">
						<div className="image">
							<div className="image-cont">
								{
									pageContent && pageContent.imagePaths && pageContent.imagePaths.length ?
										<img src={pageContent.imagePaths[0]} alt="image" style={{ height: "100%" }} />
										: <img src="images/our-images/no-image.png" style={{ height: "100%" }} />

								}
							</div>
						</div>
					</div>
					{
						exhibitions && exhibitions.length ?
							exhibitions.map((item, index) => {
								if (index % 2 === 1) {
									return <div key={index} className="exhibition-item">
										<div className="date">
											<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
											<span></span>
											<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/exhibition/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/exhibition/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
									</div>
								}
							})
							: null
					}
				</div>
				<div className="column pull-right desktop">

					<div className="title">
						{
							pageContent ?
								MainService.getValueByLanguageId(pageContent.titles, languageId)
								: null
						}
					</div>
					<div className="line"></div>
					{
						exhibitions && exhibitions.length ?
							exhibitions.map((item, index) => {
								if (index % 2 === 0) {
									return <div key={index} className="exhibition-item">
										<div className="date">
											<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
											<span></span>
											<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
											<div className="clear"></div>
										</div>
										<div className="image">
											<Link className="image-cont" to={`/exhibition/${item.id}`}>
												<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
											</Link>
										</div>
										<Link className="name" to={`/exhibition/${item.id}`}>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
									</div>
								}
							})
							: null
					}
				</div>
				<div className="clear"></div>
				{
					exhibitionsPagination && exhibitionsPagination.totalPages > exhibitionsCurrentPage && !isLoading && exhibitions.length ?
						<center style={{ display: "block" }}>
							<Link
								className='btn btn-no-str'
								to='#'
								style={{ width: "150px", marginTop: "50px" }}
								onClick={() => getExhibitionsByArtistId(exhibitionsCurrentPage + 1)}
							>
								{translate("TR_SHOW_MORE")}
							</Link>
						</center>
						: null
				}
				{
					isLoading ?
						<div style={{
							display: "flex",
							justifyContent: "center"
						}}>
							<span className="custom-loader"></span>
						</div>
						: null
				}

			</div>
		</div>
	)
}
