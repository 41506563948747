import React, { useEffect, useState } from "react";
import MotionDiv from "../animation/MotionDiv";
import { ApiService } from "../../helpers/services/apiSrevice";
import MainService from "../../helpers/services/mainService";
import { useSelector } from "react-redux";
import { useTranslation } from "../../lang/TranslationProvider";
import { useLocation } from "react-router-dom";

export default function Footer() {

    const { pathname } = useLocation()
    const { translate } = useTranslation();
    const [ready, setReady] = useState(false);
    const [contacts, setContacts] = useState([]);

    const [languageId, setLanguageId] = useState(null);
    const { language, languages } = useSelector(state => state.language);

    useEffect(() => {
        if (languages && languages.length && language) {
            setLanguageId(languages.find(item => item.isocode2 === language)?.id);
        }
    }, [languages, language]);

    useEffect(() => {
        getContacts();
    }, [])

    const getContacts = () => {
        MainService.callApi({
            api: ApiService.getContacts,
            data: {},
            cb: (response) => {
                if (response && response.data) {
                    setContacts(response.data);
                }
                setReady(true)
            },
        });
    };

    if (pathname.includes("/contact")) { return null; }

    return (
        <div className="cont">
            <div className="welcome">
                {
                    contacts && contacts.length ?
                        contacts.map((item, index) => {
                            return <div key={index}>
                                <MotionDiv
                                    initial={{ opacity: 0, y: 300 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 300 }}
                                    duration={(index + 1) / 2}
                                    ready={ready}
                                >
                                    <div className="column wow fadeInUp" data-wow-delay="0.0s">
                                        <div className="title">
                                            {MainService.getValueByLanguageId(item.cities, languageId)}
                                        </div>
                                        <div className="line"></div>
                                        <div className="text">
                                            <p>
                                                {MainService.getValueByLanguageId(item.addresses, languageId)},{MainService.getValueByLanguageId(item.descriptions, languageId)}<br />
                                                <a href={`tel:${item.phoneNumber}`}>{item.phoneNumber}</a>
                                            </p>
                                            <a className="link" href={item.mapLink} target="_blank">
                                                {translate("TR_VIEW_MAP_PHOTO")}
                                            </a>
                                        </div>
                                    </div>
                                </MotionDiv>
                            </div>
                        })
                        : null
                }
                <div className="clear"></div>
            </div>

            <footer>
                <div className="cont">
                    <div className="copy pull-left desktop">
                        <div className="img"></div>
                        <span>{new Date().getFullYear()} © All rights reserved</span>
                    </div>
                    <div className="soc pull-right">
                        {/* <a className="yb" href="https://youtube.com/user/FormAndBronze" target="_blank">
                            <i></i>
                            <span>YouTube</span>
                        </a> */}
                        <a className="fb" href="https://www.facebook.com/profile.php?id=61553036567768&mibextid=LQQJ4d&mibextid=LQQJ4d" target="_blank">
                            <i></i>
                            <span>Facebook</span>
                        </a>
                        <a className="insta" href="https://www.instagram.com/formandseasons/profilecard/?igsh=eWtudW8xODB0djg5" target="_blank">
                            <i></i>
                            <span>Instagram</span>
                        </a>
                        <div className="clear desktop"></div>
                    </div>
                    <div className="clear"></div>
                </div>
            </footer>
        </div>
    );
}
