import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        setTimeout(() => {
            scrollTop();
        }, 100);
    }, [pathname]);

    const scrollTop = () => {
        if (pathname.includes("/artist") || pathname.includes("/exhibitions") || pathname.includes("/blogs")) {
            return false;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return <div>{children}</div>;
};

export default ScrollToTop;
