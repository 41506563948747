import React from "react";
import { Spinner } from "react-bootstrap";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const LightboxComponent = ({
    isOpen,
    onHide = () => { },
    filePath = ""
}) => {
    if (!isOpen) {
        return null;
    }

    return <Lightbox
        mainSrc={filePath}
        nextSrc=""
        prevSrc=""
        onCloseRequest={onHide}
        loader={<Spinner animation="border" />}
        wrapperClassName="rounded-lg"
    />
}

export default LightboxComponent
