import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
} from "react-router-dom";
import Layaut from "../components/layaut/Layaut";
import Home from "./Home";
import Artists from "./Artists";
import Artist from "./Artist";
import Exhibitions from "./Exhibitions";
import Exhibition from "./Exhibition";
import Blogs from "./Blogs";
import Blog from "./Blog";
import Investments from "./Investments";
import Investment from "./Investment";
import Page_404 from "./Page_404";
import Contact from "./Contact";
import WishList from "./WishList";
import Orders from "./Orders";
import ShoppingCart from "./ShoppingCart";
import Order from "./Order";
import Store from "./Store";
import Profile from "./Profile";
import Artwork from "./Artwork";
import Search from "./Search";
import OrderView from "./OrderView";

const router = type => {

	return createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Layaut />}>

				<Route
					index
					element={<Home />}
				/>
				<Route
					path="artists"
					element={<Artists />}
				/>
				<Route
					path="artists/:categoryId"
					element={<Artists />}
				/>
				<Route
					path="artist/:artistId"
					element={<Artist />}
				/>
				<Route
					path="exhibitions"
					element={<Exhibitions />}
				/>
				<Route
					path="exhibitions/:itemId"
					element={<Exhibitions />}
				/>
				<Route
					path="exhibition/:itemId"
					element={<Exhibition />}
				/>
				<Route
					path="blogs"
					element={<Blogs />}
				/>
				<Route
					path="blogs/:categoryId"
					element={<Blogs />}
				/>
				<Route
					path="blog/:itemId"
					element={<Blog />}
				/>
				<Route
					path="library"
					element={<Investments />}
				/>
				<Route
					path="library-item/:itemId"
					element={<Investment />}
				/>
				<Route
					path="contact"
					element={<Contact />}
				/>
				<Route
					path="fav"
					element={<WishList />}
				/>
				<Route
					path="orders"
					element={<Orders />}
				/>
				<Route
					path="order-view/:itemId"
					element={<OrderView />}
				/>
				<Route
					path="cart"
					element={<ShoppingCart />}
				/>
				<Route
					path="order"
					element={<Order />}
				/>
				<Route
					path="store"
					element={<Store />}
				/>
				<Route
					path="store/:categoryId"
					element={<Store />}
				/>
				<Route
					path="profile"
					element={<Profile />}
				/>
				<Route
					path="artwork/:itemId"
					element={<Artwork />}
				/>
				<Route
					path="search"
					element={<Search />}
				/>
				<Route
					path="*"
					element={<Page_404 />}
				/>

			</Route>
		),
	);
};

export { router };
