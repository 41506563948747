import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ApiService } from "../helpers/services/apiSrevice";
import MainService from "../helpers/services/mainService";
import { useDispatch, useSelector } from "react-redux";
import LightboxComponent from "../components/lightbox-component/LightboxComponent";
import { useTranslation } from "../lang/TranslationProvider";
import { addProductToCart, addProductToWishList, removeProductFromWishList } from "../store/reducers/productReducer";

const Search = () => {

  const dispatch = useDispatch();
  const { translate } = useTranslation();
  const { wishListProducts, cartProducts } = useSelector(state => state.product);
  const [artistWorksSwiperRef, setArtistWorksSwiperRef] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const s = searchParams.get('s');

  const [languageId, setLanguageId] = useState(null);
  const { language, languages } = useSelector(state => state.language);

  const [isOpen, setIsOpen] = useState(false);
  const [filePath, setFilePath] = useState("");

  const [artistsSwiperRef, setArtistsSwiperRef] = useState(null);
  const [artsSwiperRef, setArtsSwiperRef] = useState(null);

  useEffect(() => {
    if (languages && languages.length && language) {
      setLanguageId(languages.find(item => item.isocode2 === language)?.id);
    }
  }, [languages, language]);

  const [data, setData] = useState(null)

  useEffect(() => {
    getSearchedData(s);
  }, [s])

  const getSearchedData = () => {
    const data = {
      pageNumber: 1,
      pageSize: 100,
      searchText: s,
    }
    MainService.callApi({
      api: ApiService.getSearchedData,
      data: data,
      cb: response => {
        if (response && response.data) {
          setData(response.data);
        }
      },
    });
  }

  return (
    <section className="cont">
      <LightboxComponent
        isOpen={isOpen}
        filePath={filePath}
        onHide={() => {
          setIsOpen(false);
          setFilePath("");
        }}
      />
      {
        data && data.artist && data.artist.pagedData && !data.artist.pagedData.length &&
          data && data.artwork && data.artwork.pagedData && !data.artwork.pagedData.length ?
          <div className="artist-list wow fadeInUp">
            <div className="title">{translate("TR_NOTHING_FOUND")}</div>
          </div>
          : null
      }
      {
        data && data.artist && data.artist.pagedData && data.artist.pagedData.length ?
          <React.Fragment>
            <div className="artist-list wow fadeInUp">
              <div className="title">{translate("TR_ARTISTS")}</div>
              <div className="nav desktop">
                <div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistsSwiperRef?.slidePrev()} />
                <div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistsSwiperRef?.slideNext()} />
              </div>
              <div className="list" style={{ width: "100%" }}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  onSwiper={(ref) => setArtistsSwiperRef(ref)}
                  loop
                  updateOnWindowResize
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 55,
                    },
                  }}
                // className="artists-swiper-wrapper"
                >
                  {
                    data.artist.pagedData.map((item, index) => {
                      return <SwiperSlide key={index}>
                        <div className="item" style={{ width: "calc(100% - 30px)" }}>
                          <div className="image-block">
                            <Link className="image" to={`/artist/${item.id}`}>
                              <div className="image-cont"
                                style={{
                                  backgroundImage: `url(${item.imagePath ? item.imagePath : "images/our-images/no-image.png"})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center"
                                }}
                              >
                              </div>
                            </Link>
                            <div className="work-list" count="6">
                              {
                                item.artworksCount > 0 ?
                                  <div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slidePrev()}></div>
                                  : null
                              }
                              <div className="list-work">
                                <div className="list-cont">
                                  {
                                    item.artworks && item.artworks.length ?
                                      <Swiper
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        loop
                                        onSwiper={(ref) => setArtistWorksSwiperRef(ref)}
                                        updateOnWindowResize
                                        breakpoints={{
                                          1024: {
                                            slidesPerView: 4,
                                            spaceBetween: 0,
                                          },
                                        }}
                                        className="mySwiper"
                                      >
                                        {
                                          item.artworks.map((item, index) => {
                                            return <SwiperSlide key={index}>
                                              <span
                                                style={{ cursor: "pointer" }}
                                                className="work-image fancybox"
                                                onClick={() => {
                                                  setIsOpen(true);
                                                  setFilePath(item.imagePath ? item.imagePath : "images/our-images/no-image.png")
                                                }}
                                              >
                                                <img
                                                  src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"}
                                                  style={{ height: "100%" }}
                                                  alt="image"
                                                />
                                              </span>
                                            </SwiperSlide>
                                          })
                                        }
                                      </Swiper>
                                      : null
                                  }
                                </div>
                              </div>
                              {
                                item.artworksCount > 0 ?
                                  <div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artistWorksSwiperRef?.slideNext()}></div>
                                  : null
                              }
                              <div className="clear"></div>
                            </div>
                          </div>
                          <Link className="name" to={`/artist/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
                          <div className="line"></div>
                          <div className="count">{item.artworksCount} {translate("TR_WORK")}</div>
                          <Link className="view" to={`/artist/${item.id}`}>{translate("TR_VIEW")}</Link>
                          <div className="clear"></div>
                        </div>
                      </SwiperSlide>
                    })
                  }

                </Swiper>
              </div>
            </div>
            <React.Fragment>
              <div className="clear"></div>
              <div className="mobile btn-all-artist">
                <Link className="btn" to="/artists">{translate("TR_VIEW_ALL_ARTISTS")}</Link>
              </div>
            </React.Fragment>
          </React.Fragment>
          : null
      }

      {
        data && data.artwork && data.artwork.pagedData && data.artwork.pagedData.length ?
          <React.Fragment>
            <div className="product-list product-list-line wow fadeInUp">
              <div className="title">{translate("TR_STORE")}</div>
              <div className="nav desktop">
                <div className="prev d-block" style={{ cursor: "pointer" }} onClick={() => artsSwiperRef?.slidePrev()} />
                <div className="next d-block" style={{ cursor: "pointer" }} onClick={() => artsSwiperRef?.slideNext()} />
              </div>
              <div className="list search-page-artworks" style={{ display: "flex", flexWrap: "wrap" }}>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  onSwiper={(ref) => setArtsSwiperRef(ref)}
                  loop
                  updateOnWindowResize
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 55,
                    },
                  }}
                  className="mySwiper"
                >
                  {
                    data.artwork.pagedData.map((item, index) => {
                      const isInWishList = wishListProducts.some(el => el.id === item.id);
                      const isInShoppingCart = cartProducts.some(el => el.id === item.id);
                      return <SwiperSlide key={index}>
                        <div className="item">
                          <div
                            className={`fav to-fav ${isInWishList ? 'remove-fav' : ''}`}
                            onClick={() => {
                              if (isInWishList) {
                                dispatch(removeProductFromWishList(item));
                              } else {
                                dispatch(addProductToWishList(item));
                              }
                            }}
                          />
                          <Link className="image" to={`/artwork/${item.id}`}>
                            <div className="image-cont">
                              <img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} />
                            </div>
                          </Link>
                          {
                            // item.price || item.oldPrice ?
                              <Link
                                className="cart add-to-cart"
                                to={`${isInShoppingCart ? "/cart" : "#"}`}
                                onClick={() => {
                                  if (!isInShoppingCart) {
                                    dispatch(addProductToCart(item));
                                    alert(translate("TR_ADDED_TO_CART"))
                                  }
                                }}
                              >
                              </Link>
                              // : null
                          }
                          <Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
                          <span>{MainService.getValueByLanguageId(item.artistNames, languageId)}</span>
                          <div className="line"></div>
                          <b className="price">
                            <span></span> &nbsp;
                          </b>
                          <Link className="view" to={`/artwork/${item.id}`}>{translate("TR_VIEW")}</Link>
                          <div className="clear"></div>
                        </div>
                      </SwiperSlide>
                    })
                  }
                </Swiper>
              </div>
            </div>

            <React.Fragment>
              <div className="clear"></div>
              <div className="mobile btn-online-store">
                <Link className="btn" to="/store">{translate("TR_ONLINE_STORE")}</Link>
              </div>
            </React.Fragment>
          </React.Fragment>
          : null
      }

    </section>

  )
}

export default Search