import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { useTranslation } from "../lang/TranslationProvider";
import { ApiService } from "../helpers/services/apiSrevice";
import { Table } from "react-bootstrap";
import moment from "moment";

export default function Orders() {

	const targetRef = useRef(null);
	const { translate } = useTranslation();
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);

	const [pagination, setPagination] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user) {
			navigate("/")
		}
	}, [user])

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getOrders(1);
	}, [user])

	const getOrders = (currentPage) => {
		if (!user) { return false };

		const data = {
			pageNumber: currentPage,
			pageSize: 100,
			userId: user.id,
		}
		setIsLoading(true)
		MainService.callApi({
			api: ApiService.getOrders,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagination) {
						setPagination(data.pagination)
					}
					if (data.pagedData && data.pagedData.length) {
						setOrders(data.pagedData);
					} else {
						setOrders([]);
					}
				}
				setCurrentPage(currentPage)
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}


	return (
		<div className="cont">
			<div className="catalog">
				<div className="catalog-list pull-right" style={{ width: "100%" }}>
					<h1 className="pull-left">{translate("TR_ORDERS")}</h1>
					<div className="clear"></div>
					{
						isLoading ?
							<div style={{
								display: "flex",
								justifyContent: "center"
							}}>
								<span className="custom-loader"></span>
							</div>
							: <table id="orders">
								<tr>
									<th scope="col">#</th>
									<th scope="col" className='text-nowrap'><p>User Name</p></th>
									<th scope="col" className='text-nowrap'>Email</th>
									<th scope="col" className='text-nowrap'>Phone Number</th>
									<th scope="col" className='text-nowrap'>Artworks Count</th>
									<th scope="col" className='text-nowrap'>Total Price</th>
									<th scope="col" className='text-nowrap'>Create Date</th>
									<th scope="col" className='text-nowrap'>Action</th>
								</tr>
								{
									orders.map((item, index) => {
										return <tr key={index}>
											<td scope="row">{index + 1}</td>
											<td>{item.userFullName}</td>
											<td>{item.userEmail}</td>
											<td>{item.userPhoneNumber}</td>
											<td>{item.artworksCount}</td>
											<td>{item.totalPrice ? `${MainService.formatCurrency(item.totalPrice, 2)}₽` : ""}</td>
											<td>{moment(item?.createDate || "").format("LL HH:mm")}</td>
											<td>
												<div className='d-flex gap-2'>
													<Link
														to={`/order-view/${item.id}`}
														className="btn btn-outline-primary"
														title={translate("TR_EDIT")}
														style={{ textTransform: "capitalize", background: "none", padding: "10px 20px" }}
													>
														{translate("TR_VIEW")}
													</Link>

												</div>
											</td>
										</tr>
									})
								}
							</table>
					}



				</div>
				<div className="clear"></div>
			</div>


		</div>
	)

}
