import { createSlice } from '@reduxjs/toolkit';

const isJson = (str) => {
  try {
    const parsed = JSON.parse(str);
    return typeof parsed === "object";
  } catch (e) {
    return false;
  }
}

const getInitialState = (key) => {
  const data = localStorage.getItem(key);
  return data && isJson(data) ? JSON.parse(data) : [];
}

const initialState = {
  wishListProducts: getInitialState("wishListProducts"),
  cartProducts: getInitialState("cartProducts"),
};

const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
}

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProductToWishList(state, { payload }) {
      const newProducts = [...state.wishListProducts, payload];
      saveToLocalStorage("wishListProducts", newProducts);
      state.wishListProducts = newProducts;
    },
    removeProductFromWishList(state, { payload }) {
      const newProducts = state.wishListProducts.filter(item => item.id !== payload.id);
      saveToLocalStorage("wishListProducts", newProducts);
      state.wishListProducts = newProducts;
    },
    addProductToCart(state, { payload }) {
      const newProducts = [...state.cartProducts, payload];
      saveToLocalStorage("cartProducts", newProducts);
      state.cartProducts = newProducts;
    },
    addProductsToCart(state, { payload }) {
      saveToLocalStorage("cartProducts", payload);
      state.cartProducts = payload;
    },
    removeProductFromCart(state, { payload }) {
      const newProducts = state.cartProducts.filter(item => item.id !== payload.id);
      saveToLocalStorage("cartProducts", newProducts);
      state.cartProducts = newProducts;
    },
    removeProductsFromCart(state, { payload }) {
      localStorage.removeItem("cartProducts")
      state.cartProducts = [];
    },
  },
});

export const { addProductToWishList, removeProductFromWishList, addProductToCart, removeProductFromCart, addProductsToCart, removeProductsFromCart } = productSlice.actions;
export default productSlice.reducer;
