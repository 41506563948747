import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "../lang/TranslationProvider";
import MainService from "../helpers/services/mainService";
import AlertService from "../helpers/services/alertService";
import { ALL_VALID_IMAGE_TYPES } from "../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../helpers/services/apiSrevice";
import { setCurrentUser } from "../store/reducers/userReducer";

export default function Profile() {

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { translate } = useTranslation();
	const { user } = useSelector(state => state.user);

	const [isLoading, setIsLoading] = useState(false);
	const [uploadedImage, setUploadedImage] = useState(null);

	useEffect(() => {
		if (!user) {
			navigate("/")
		}
	}, [user])

	useEffect(() => {
		if (user) {
			setValues((values) => ({
				...values,
				firstName: user?.firstName || "",
				lastName: user?.lastName || "",
				email: user?.email || "",
				phoneNumber: user?.phoneNumber || "",
			}))
			if(user.address){
				setAddressForm((prev) => ({
					...prev,
					fullName: user.address.fullName || "",
					country: user.address.country || "",
					city: user.address.city || "",
					province: user.address.province || "",
					address1: user.address.address1 || "",
					address2: user.address.address2 || "",
					index: user.address.index || "",
					description: user.address.description || "",
				}))
			}
		}
	}, [user])

	const [values, setValues] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
	})

	const [addressForm, setAddressForm] = useState({
		fullName: "",
		country: "",
		city: "",
		province: "",
		address1: "",
		address2: "",
		index: "",
		description: "",
	});

	const uploadFile = async (files) => {
		if (files && files.length) {
			for (let i in files) {
				const file = files[i];
				if (file && typeof file === "object") {
					const fileName = file.name;
					await MainService.readFile(file, ALL_VALID_IMAGE_TYPES)
						.then(uploadedFile => {
							setUploadedImage({
								fileName,
								file,
								uploadedFile,
							})
						})
						.catch(error => error && AlertService.alert("warning", error));
				}
			}
		}
	};

	const updateAvatar = (event) => {
		event && event.preventDefault();
		event && event.stopPropagation();

		if (!uploadedImage || isLoading) {
			return false;
		}
		setIsLoading(true);
		const formData = new FormData();
		formData.append("formFile", uploadedImage.file);
		MainService.callApi({
			api: ApiService.updateAvatar,
			data: formData,
			cb: () => {
				AlertService.alert("success", "Data saved");
				getCurrentUser();
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}

	const updateUserData = (event) => {
		if (isLoading) {
			return false;
		}
		event && event.preventDefault();
		setIsLoading(true);
		MainService.callApi({
			api: ApiService.updateUserData,
			data: values,
			cb: () => {
				AlertService.alert("success", "Data saved");
				getCurrentUser();
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}

	const deleteAvatar = (event) => {
		event && event.preventDefault();
		event && event.stopPropagation();

		setIsLoading(true);
		MainService.callApi({
			api: ApiService.deleteAvatar,
			data: {},
			cb: () => {
				AlertService.alert("success", "Data saved");
				getCurrentUser();
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}

	const removeUploadedImage = () => {
		setUploadedImage(null);
	}

	const updateUserAddress = (event) => {
		if (isLoading) {
			return false;
		}
		event && event.preventDefault();
		setIsLoading(true);
		addressForm.id = user.address?.id;
		MainService.callApi({
			api: ApiService.updateUserAddress,
			data: addressForm,
			cb: () => {
				getCurrentUser();
				AlertService.alert("success", "Data saved")
			},
			_finally: () => {
				setIsLoading(false);
			}
		});
	}

	const getCurrentUser = async () => {
		MainService.callApi({
			api: ApiService.getCurrentUser,
			cb: response => {
				if (response && response.data) {
					dispatch(setCurrentUser(response.data));
				}
			},
		});
	}

	return (user ?
		<section className="cont">
			<Link className="back-btn" to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
			<div className="profile">
				<div className="profile-form pull-left">


					<form onSubmit={updateUserData} className={`profile-form-item ${isLoading ? "disabled" : ""}`} >
						<input name="id" type="hidden" value="15" />
						<div className="title">{translate("TR_YOUR_CONTACT_INFORMATION")}</div>
						{
							user.avatarPath ?
								<div style={{ position: "relative", display: "inline-block" }}>
									<img
										src={user.avatarPath}
										width={150}
										style={{ borderRadius: "5px", marginBottom: "20px", maxWidth: "300px", minWidth: "100px" }}
									/>
									<div
										className="cart-item"
										style={{ position: "absolute", border: "none", top: "-20px", right: "-20px", margin: "0", padding: "0" }}
									>
										<div className="remove remove-from-fart" onClick={deleteAvatar} />
									</div>
								</div>
								: uploadedImage ?
									<div style={{ position: "relative", display: "inline-block" }}>
										<img
											src={uploadedImage.uploadedFile}
											width={150}
											style={{ borderRadius: "5px", marginBottom: "20px", maxWidth: "300px", minWidth: "100px" }}
										/>
										<div
											className="cart-item"
											style={{ position: "absolute", border: "none", top: "-20px", right: "-20px", margin: "0", padding: "0" }}
										>
											<div className="remove remove-from-fart" onClick={removeUploadedImage} />
											<div className="check remove-from-fart" onClick={updateAvatar} style={{ top: "23px", left: "15px" }} />
										</div>
									</div>
									: null
						}

						{
							user.avatarPath ? null
								: <div className={`form-item`}>
									<input
										className="w-100"
										name=""
										placeholder={translate("TR_CHOOSE_A_PHOTO")}
										type="text"
									/>
									<input
										className="w-100"
										id="user-cover"
										name="user-cover"
										type="file"
										multiple={false}
										disabled={user.avatarPath}
										onChange={(e) => uploadFile(e.target.files)}
									/>
									<label htmlFor="user-cover">{translate("TR_CHOOSE_A_PHOTO")}</label>
								</div>
						}

						<div className="form-item">
							<input
								className="w-100"
								name="name"
								placeholder={translate("TR_FULL_NAME")}
								type="text"
								value={values.firstName}
								onChange={(event) => MainService.onChange(event, "firstName", setValues)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="email"
								placeholder={translate("TR_EMAIL")}
								type="email"
								value={values.email}
								onChange={(event) => MainService.onChange(event, "email", setValues)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="phone"
								placeholder={translate("TR_PHONE_NUMBER")}
								type="tel"
								value={values.phoneNumber}
								onChange={(event) => MainService.onChange(event, "phoneNumber", setValues)}
							/>
						</div>
						<div className="form-btn">
							<input
								type="submit"
								style={{ cursor: "pointer" }}
								className="btn btn-no-str btn-save btn-save-1"
								value={translate("TR_SAVE_YOUR_DATA")}
							/>
						</div>
					</form>
					<form onSubmit={updateUserAddress} className={`profile-form-item ${isLoading ? "disabled" : ""}`} >
						<input name="id" type="hidden" value="15" />
						<div className="title">
							{translate("TR_SHIPPING_AND_BILLING_ADDRESSES")}
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="full_name"
								placeholder={translate("TR_FULL_NAME")}
								type="text"
								value={addressForm.fullName}
								onChange={(event) => MainService.onChange(event, "fullName", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="country"
								placeholder={translate("TR_COUNTRY")}
								type="text"
								value={addressForm.country}
								onChange={(event) => MainService.onChange(event, "country", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="city"
								placeholder={translate("TR_CITY")}
								type="text"
								value={addressForm.city}
								onChange={(event) => MainService.onChange(event, "city", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="province"
								placeholder={translate("TR_STATE")}
								type="text"
								value={addressForm.province}
								onChange={(event) => MainService.onChange(event, "province", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="address1"
								placeholder={translate("TR_ADDRESS")}
								type="text"
								value={addressForm.address1}
								onChange={(event) => MainService.onChange(event, "address1", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="address2"
								placeholder={translate("TR_BUILDING")}
								type="text"
								value={addressForm.address2}
								onChange={(event) => MainService.onChange(event, "address2", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="index"
								placeholder={translate("TR_ZIP_CODE")}
								type="text"
								value={addressForm.index}
								onChange={(event) => MainService.onChange(event, "index", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<textarea
								className="w-100"
								name="description"
								placeholder={translate("TR_COMMENT")}
								value={addressForm.description}
								onChange={(event) => MainService.onChange(event, "description", setAddressForm)}
							/>
						</div>
						<div className="form-btn">
							<input
								className="btn btn-no-str btn-save btn-save-2"
								type="submit"
								style={{ cursor: "pointer" }}
								value={translate("TR_SAVE_YOUR_DATA")}
							/>
						</div>
					</form>
				</div>
				<div className="profile-info pull-right">
					<div className="image" style={{ display: "flex" }}>
						<img
							style={{ width: "100%" }}
							src={user.avatarPath ? user.avatarPath : "images/our-images/no-image.png"}
						/>
					</div>
					<div className="name">{user?.firstName} {user?.lastName}</div>
					<div className="clear"></div>
					<div className="bottom">{translate("TR_YOUR_DISCOUNT")} 5%</div>
				</div>
				<div className="clear"></div>
			</div>
		</section>
		: null
	)

}
