import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const TranslationContext = createContext();

export default function TranslationProvider({ children }) {
  const { translations } = useSelector((state) => state.translation);
  const [currentTranslations, setCurrentTranslations] = useState([]);

  useEffect(() => {
    setCurrentTranslations(translations || [])
  }, [translations])

  const translate = (key) => {
    const translation = currentTranslations.find(item => item.shortKey === key);
    return translation ? translation.text : key;
  };

  return (
    <TranslationContext.Provider value={{ translate }}>
      {/* {
        translations && translations.length ?
          children
          : null
      } */}
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(TranslationContext);
};
