import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useSelector } from "react-redux";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { Link } from "react-router-dom";
import { useTranslation } from "../lang/TranslationProvider";
import parse from 'html-react-parser';
import Letter from "../components/letter/Letter";


const MapComponent = ({ cordinates }) => {

	const containerStyle = {
		width: '100%',
		height: '370px'
	};

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		// googleMapsApiKey: "AIzaSyBlUjFV-Xts_zQ_rM9hxDvhzrUQU5MY2UU",
		googleMapsApiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
	})

	const [map, setMap] = useState(null);
	const [zoom, setZoom] = useState(0);


	const onLoad = React.useCallback(function callback(map) {
		// This is just an example of getting and using the map instance!!! don't just blindly copy!
		const bounds = new window.google.maps.LatLngBounds(cordinates);
		map.fitBounds(bounds);
		setMap(map);
		setZoom(15)
	}, [])


	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={containerStyle}
			center={cordinates}
			zoom={zoom}
			onLoad={onLoad}
			onUnmount={onUnmount}
		/>
	) : <></>
}

export default function Contact() {

	const { translate } = useTranslation();

	const [contactPageContent, setContactPageContent] = useState(null);

	const [aboutUsPageContent, setAboutUsPageContent] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const [languageId, setLanguageId] = useState(null);
	const [contacts, setContacts] = useState([]);
	const [activeContact, setActiveContact] = useState(null);
	const [isShowModal, setIsShowModal] = useState(false);

	useEffect(() => {
		getPageContentByKeyName("KEY_CONTACT", setContactPageContent);
		getPageContentByKeyName("KEY_ABOUT_US", setAboutUsPageContent);
		getContacts();
	}, [])

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	const getPageContentByKeyName = (key, cb) => {
		MainService.callApi({
			api: ApiService.getPageContentByKeyName,
			data: key,
			cb: response => {
				if (response && response.data) {
					cb(response.data);
				}
			},
		});
	}

	const getContacts = () => {
		MainService.callApi({
			api: ApiService.getContacts,
			data: {},
			cb: (response) => {
				if (response && response.data && response.data.length) {
					setContacts(response.data);
					setActiveContact(response.data[0])
				}
			},
		});
	};



	return (
		<React.Fragment>
			<div className="cont">
				{
					contactPageContent ?
						<div className="main-page-bunner contact-page-bunner">
							<div className="image pull-left">
								<div className="image-cont">
									{
										contactPageContent.imagePaths && contactPageContent.imagePaths.length ?
											<img src={contactPageContent.imagePaths[0]} alt="image" style={{ height: "100%" }} />
											: <img src="images/our-images/no-image.png" style={{ height: "100%" }} />
									}
								</div>
							</div>
							<div className="text pull-right">
								<span className="label">{MainService.getValueByLanguageId(contactPageContent.titles, languageId)}</span>
								<div className="line"></div>
								<div className="tags tags-contacts">
									<div className="tags-cont">
										{
											contacts && contacts.length ?
												contacts.map((item, index) => {
													return <Link
														key={index}
														className={activeContact?.id === item.id ? "active" : ""}
														to="#"
														onClick={() => {
															setActiveContact(item)
														}}
													>
														{MainService.getValueByLanguageId(item.cities, languageId)}
													</Link>
												})
												: null
										}
										<div className="clear"></div>
									</div>
								</div>
								{
									activeContact ?
										<div className="tabs-cont active" id="tab-1">
											<div className="name">{MainService.getValueByLanguageId(activeContact.addresses, languageId)}</div>
											<div className="small-address">{MainService.getValueByLanguageId(activeContact.descriptions, languageId)}</div>
											<a className="phone" href={`tel:${activeContact.phoneNumber}`}>{activeContact.phoneNumber}</a>
										</div>
										: null
								}
								<Link
									to="#"
									className="btn btn-no-str btn-write"
									style={{ textWrap: "nowrap", display: "inline-table" }}
									onClick={() => {
										setIsShowModal(true);
									}}
								>
									<span
										style={{ display: "block", marginLeft: "10px" }}
										onClick={() => setIsShowModal(true)}
									>
										{translate("TR_WRITE_A_LETTER")}
									</span>
								</Link>
							</div>
							<div className="clear"></div>
						</div>
						: null
				}
				<div className="contact-page-map">
					<div className="title">{translate("TR_LOCATION_MAP")}</div>
					<div className="map">
						{
							activeContact ?
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d617.7896107774759!2d44.51150258227032!3d40.177461257711634!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd23520dc73b%3A0xcdc9550708b75e61!2sForm%20%26%20Seasons!5e1!3m2!1sen!2sam!4v1733825580622!5m2!1sen!2sam"
									style={{ border: 0, width: "100%", height: "100%" }}
									allowFullScreen=""
									loading="lazy"
									referrerPolicy="no-referrer-when-downgrade"
									title="Google Maps"
								></iframe>
								// <MapComponent cordinates={{ lat: +activeContact.mapCoordinateLat, lng: +activeContact.mapCoordinateLong }} />
								: null
						}
					</div>
					{
						aboutUsPageContent ?
							<div className="contact-page-text">
								<div className="title">
									{MainService.getValueByLanguageId(aboutUsPageContent.titles, languageId)}
								</div>
								<div className="text">
									{
										parse(MainService.getValueByLanguageId(aboutUsPageContent.contents, languageId))
									}
								</div>
							</div>
							: null
					}
				</div>
			</div>
			<Letter isShowModal={isShowModal} setIsShowModal={setIsShowModal} />
		</React.Fragment>
	)

}
