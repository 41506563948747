import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../lang/TranslationProvider";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';

export default function Page_404() {

	const { translate } = useTranslation();

	const SetMessage = () => {
		const text = translate("TR_NOT_FOUND_TEXT_INFO_MSG");
		const text_0 = translate("TR_HOME_PAGE");
		const text_1 = translate("TR_CATALOGUE_OF_WORKS");
		const text_2 = translate("TR_NOT_FOUNT_PAGE_ARTISTS");
		const text_3 = translate("TR_NOT_FOUNT_PAGE_EXHIBITIONS");

		// Разделяем текст на части, чтобы вставить ссылки
		const parts = text.split(/(\{0\}|\{1\}|\{2\}|\{3\})/);

		// Заменяем плейсхолдеры на соответствующие ссылки
		const content = parts.map((part, index) => {
			switch (part) {
				case '{0}':
					return <Link key={index} to="/">{text_0}</Link>;
				case '{1}':
					return <Link key={index} to="/store">{text_1}</Link>;
				case '{2}':
					return <Link key={index} to="/artists">{text_2}</Link>;
				case '{3}':
					return <Link key={index} to="/exhibitions">{text_3}</Link>;
				default:
					return part;
			}
		});

		return <p>{content}</p>;
	};


	return (
		<div className="cont" style={{ height: "65vh" }}>
			<h1
				style={{
					fontFamily: "Playfair Display",
					fontSize: "40px",
					lineHeight: "54px",
					marginBottom: "44px",
				}}
			>
				{translate("TR_ERROR")} <span style={{ fontSize: "60px" }}>404</span>: {translate("TR_PAGE_NOT_FOUND")}</h1>
			<p
				style={{
					fontWeight: "300",
					fontSize: "16px",
					lineHeight: "31px",
					letterSpacing: "0.1em",
					color: "#fff"
				}}
			>
				{/* {parse(setMessage())} */}
				<SetMessage />
				{/* К сожалению, данной страницы не существует. Вы можете перейти на <Link to="/">главную страницу</Link>
				, ознакомиться с
				<Link to="/store">каталогом работ</Link> или узнать больше о <Link to="/artists">художниках</Link>
				и
				<Link to="/exhibitions">выставках</Link>. */}
			</p>
		</div>
	)
}




