// import { createSlice } from "@reduxjs/toolkit";

// const isJson = (str) => {
// 	try {
// 		JSON.parse(str);
// 	} catch (e) {
// 		return false;
// 	}
// 	return true;
// }


// const userSlice = createSlice({
// 	name: "user",
// 	initialState: {
// 		user: isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
// 		token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
// 	},
// 	reducers: {
// 		setUserData: (state, { payload }) => {
// 			state.user = payload;
// 		},
// 		setLogout(state, action) {
// 			state.user = null;
// 		},

// 		setToken: (state, { payload }) => {
// 			state.token = payload;
// 		},
// 	},
// });

// export const {
// 	setUserData,
// 	setLogout,
// 	setToken,
// } = userSlice.actions;

// export default userSlice.reducer;



import { createSlice } from '@reduxjs/toolkit';

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const initialState = {
  user: localStorage.getItem("user") && isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
};

const translationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
      state.isLogin = true;
    },
    setLogout(state, action) {
      localStorage.removeItem("user")
      state.user = null;
      state.isLogin = false;
    },
    setToken: (state, { payload }) => {
      if(payload){
        localStorage.setItem('token', payload);
      }else{
        localStorage.removeItem('token');
      }
      state.token = payload;
    },
  },
});

export const { setCurrentUser, setToken, setLogout } = translationSlice.actions;
const userReducer = translationSlice.reducer;

export default userReducer;
