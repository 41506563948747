import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { useTranslation } from "../lang/TranslationProvider";
import { removeProductFromWishList } from "../store/reducers/productReducer";

export default function WishList() {

	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { wishListProducts } = useSelector(state => state.product);
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	return (
		<div className="cont">
			<div className="catalog">
				<div className="catalog-list pull-right" style={{ width: "100%" }}>
					<h1 className="pull-left">{translate("TR_WISH_LIST")}</h1>
					<div className="clear"></div>
					<div className="product-list product-list-no-shadow">
						<div className="list" style={{ display: "flex", flexWrap: "wrap" }}>
							{
								wishListProducts && wishListProducts.length ?
									wishListProducts.map((item, index) => {
										return <div key={index} className="item">
											<div
												className="fav to-fav remove-fav"
												onClick={() => {
													dispatch(removeProductFromWishList(item));
												}}
											/>
											<Link className="image" to={`/artwork/${item.id}`}>
												<div className="image-cont">
													<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} alt="image" style={{ height: "100%" }} />
												</div>
											</Link>
											<Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
											<span>{MainService.getValueByLanguageId(item.artistNames, languageId)}</span>
											<div className="line"></div>
											<b className="price">
												{item.price ? `${item.price}₽` : translate("TR_PRICE_ON_REQUEST")}
											</b>
											<Link className="view" to={`/artwork/${item.id}`}>{translate("TR_VIEW")}</Link>
											<div className="clear"></div>
										</div>
									})
									: <p>{translate("TR_NO_PRODUCTS")}</p>
							}

							<div className="clear"></div>
						</div>
					</div>
				</div>
				<div className="clear"></div>
			</div>


		</div>
	)

}
