import React, { useEffect, useRef, useState } from "react";

export const ViewPosition = ({ children, width, ...props }) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef(null);

	function handleScroll() {
		let view_bearer = 150;
		if (window.innerWidth < 900) {
			view_bearer = 100;
		} else if (window.innerWidth < 600) {
			view_bearer = 75;
		}
		if (ref.current) {
			const { top, bottom } = ref.current.getBoundingClientRect(),
				window_height = window.innerHeight;

			if (top < window_height - view_bearer && bottom > view_bearer) {
				setIsVisible(true);
			}
		}
	}
	useEffect(() => {
		document.addEventListener("scroll", handleScroll);
		return () => {
			document.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div {...props} ref={ref} style={{ width: !width && "100%" }}>
			{children(isVisible)}
		</div>
	);
};
