import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { setTranslations } from '../store/reducers/translationReducer';
import { ApiService } from '../helpers/services/apiSrevice';
import { setLanguages } from '../store/reducers/languageReducer';

const LangProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.language);

  useEffect(() => {
    getLanguages();
  }, [])

  const getLanguages = () => {
    ApiService.getLanguages().then(response => {
      if (response && response.data) {
        dispatch(setLanguages(response.data))
      } else {
        dispatch(setLanguages([]))
      }
    }).catch(error => console.log(error)
    )
  }

  useEffect(() => {
    if (language) {
      localStorage.setItem("language", language);
      setTimeout(() => {
        getTranslations();
      }, 100);
    }
  }, [language])

  const getTranslations = () => {
    ApiService.getAllTranslations().then(response => {
      if (response && response.data) {
        dispatch(setTranslations(response.data))
      } else {
        dispatch(setTranslations([]))
      }
    }).catch(error => console.log(error)
    )
  }

  return (
    <React.Fragment>
      <IntlProvider>
        {children}
      </IntlProvider>
    </React.Fragment>
  );
};
export default LangProvider;
