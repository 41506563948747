import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  artistData: null,
};

const artistSlice = createSlice({
  name: 'artist',
  initialState,
  reducers: {
    setArtistData(state, action) {
      state.artistData = action.payload;
    },
  },
});

export const { setArtistData } = artistSlice.actions;
const artistReducer = artistSlice.reducer;

export default artistReducer;
