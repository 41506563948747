import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../lang/TranslationProvider";
import { Link, useNavigate } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { removeProductsFromCart } from "../store/reducers/productReducer";

export default function Order() {

	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { cartProducts } = useSelector(state => state.product);
	const { user } = useSelector(state => state.user);
	const navigate = useNavigate();

	const calculateTotalPrice = (products) => {
		return products.reduce((total, product) => total + product.price, 0);
	};

	const calculateDiscountedPrice = (products, discountPercentage) => {
		return products.reduce((total, product) => total + product.price, 0) * (1 - discountPercentage / 100);
	}

	useEffect(() => {
		if (!cartProducts || !cartProducts.length) {
			navigate("/");
		}
	}, [cartProducts])

	useEffect(() => {
		if (user) {
			setValues((values) => ({
				...values,
				userFullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
				userEmail: user?.email || "",
				userPhoneNumber: user?.phoneNumber || "",
			}))
			if(user.address){
				setAddressForm((prev) => ({
					...prev,
					fullName: user.address.fullName || "",
					country: user.address.country || "",
					city: user.address.city || "",
					province: user.address.province || "",
					address1: user.address.address1 || "",
					address2: user.address.address2 || "",
					index: user.address.index || "",
					description: user.address.description || "",
				}))
			}
		}
	}, [user])

	const [values, setValues] = useState({
		userFullName: "",
		userEmail: "",
		phoneNumber: "",
	})

	const [addressForm, setAddressForm] = useState({
		fullName: "",
		country: "",
		city: "",
		province: "",
		address1: "",
		address2: "",
		index: "",
		description: "",
	});

	const removeEmptyFields = (obj) => {
		const newObj = {};
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];
				if (value !== "" && value !== null && value !== undefined) {
					newObj[key] = value;
				}
			}
		}
		return newObj;
	};

	const onSubmit = (event) => {
		event && event.preventDefault();
		const data = { ...values, ...addressForm }
		data.orderArtworks = cartProducts;
		MainService.callApi({
			api: ApiService.makeOrder,
			data: removeEmptyFields(data),
			cb: () => {
				alert(translate("TR_ORDER_MSG"));
				dispatch(removeProductsFromCart());
				navigate("/")
			},
		});
	}

	return (
		<div className="cont">
			<Link className="back-btn" to="/cart">{translate("TR_BACK_TO_BASKET")}</Link>
			<div className="cart">
				<div className="profile-form pull-left">
					<form onSubmit={onSubmit} className="profile-form-item">
						<input name="id" type="hidden" value="15" />
						<div className="title">{translate("TR_YOUR_CONTACT_INFORMATION")}</div>
						<div className="form-item">
							<input
								className="w-100"
								name="userFullName"
								placeholder={translate("TR_FULL_NAME")}
								type="text"
								value={values.userFullName}
								onChange={(event) => MainService.onChange(event, "userFullName", setValues)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="userEmail"
								placeholder={translate("TR_EMAIL")}
								type="email"
								value={values.userEmail}
								onChange={(event) => MainService.onChange(event, "userEmail", setValues)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="phone"
								placeholder={translate("TR_PHONE_NUMBER")}
								type="text"
								value={values.userPhoneNumber}
								onChange={(event) => MainService.onChange(event, "userPhoneNumber", setValues)}
							/>
						</div>
					</form>
					<div
						className="profile-form-item"
					>
						<input name="id" type="hidden" value="15" />
						<div className="title">
							{translate("TR_SHIPPING_AND_BILLING_ADDRESSES")}
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="full_name"
								placeholder={translate("TR_FULL_NAME")}
								type="text"
								value={addressForm.fullName}
								onChange={(event) => MainService.onChange(event, "fullName", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="country"
								placeholder={translate("TR_COUNTRY")}
								type="text"
								value={addressForm.country}
								onChange={(event) => MainService.onChange(event, "country", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="city"
								placeholder={translate("TR_CITY")}
								type="text"
								value={addressForm.city}
								onChange={(event) => MainService.onChange(event, "city", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="province"
								placeholder={translate("TR_STATE")}
								type="text"
								value={addressForm.province}
								onChange={(event) => MainService.onChange(event, "province", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="address1"
								placeholder={translate("TR_ADDRESS")}
								type="text"
								value={addressForm.address1}
								onChange={(event) => MainService.onChange(event, "address1", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="address2"
								placeholder={translate("TR_BUILDING")}
								type="text"
								value={addressForm.address2}
								onChange={(event) => MainService.onChange(event, "address2", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<input
								className="w-100"
								name="zip_code"
								placeholder={translate("TR_ZIP_CODE")}
								type="text"
								value={addressForm.index}
								onChange={(event) => MainService.onChange(event, "index", setAddressForm)}
							/>
						</div>
						<div className="form-item">
							<textarea
								className="w-100"
								name="description"
								placeholder={translate("TR_COMMENT")}
								value={addressForm.description}
								onChange={(event) => MainService.onChange(event, "description", setAddressForm)}
							/>
						</div>
					</div>
				</div>
				<div className="cart-right pull-right">
					<div className="cart-panel">
						<div className="title">{translate("TR_BASKET_TOTALS")}</div>
						<div className="cart-line">
							<span>{translate("TR_SUB_TOTAL")}</span>
							<div className="price price-old">
								<b>{calculateTotalPrice(cartProducts)} ₽</b>
							</div>
							<div className="clear"></div>
						</div>
						<div className="total">
							<div className="left">{translate("TR_TOTAL")}</div>
							<div className="right">{calculateDiscountedPrice(cartProducts, 5)} ₽</div>
							<div className="clear"></div>
						</div>
						<Link className="btn btn-no-str btn-order" to="#" onClick={onSubmit}>{translate("TR_MAKE_ORDER")}</Link>
					</div>
				</div>
				<div className="clear"></div>
			</div>
		</div>
	)

}
