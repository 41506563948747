import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import { motion } from "framer-motion";
import { useTranslation } from "../../lang/TranslationProvider";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../store/reducers/languageReducer";
import { setActiveCategoryId, setActiveSubCategoryId, setIsShowFilterMenu } from "../../store/reducers/categoryReducer";
import MainService from "../../helpers/services/mainService";
import { removeProductFromCart, removeProductFromWishList } from "../../store/reducers/productReducer";
import AuthComponent from "./components/AuthComponent";

export default function Header() {

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { translate } = useTranslation();
    const { translations } = useSelector((state) => state.translation);
    const { wishListProducts, cartProducts } = useSelector(state => state.product);
    const { language, languages } = useSelector(state => state.language)
    const { categories, activeCategoryId, activeSubCategoryId, isShowFilterMenu } = useSelector(state => state.category);
    const { artistData } = useSelector(state => state.artist);
    const windowSize = useWindowSize();
    const [isActive, setIsActive] = useState(false);
    const [isShowSearchMenu, setIsShowSearchMenu] = useState(false);
    const [isShowMobilemenu, setIsShowMobilemenu] = useState(false);
    const [isShowLanguageDropdown, setIsShowLanguageDropdown] = useState(false);
    const [languageId, setLanguageId] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (languages && languages.length && language) {
            setLanguageId(languages.find(item => item.isocode2 === language)?.id);
        }
    }, [languages, language]);

    useEffect(() => {
        if (isShowSearchMenu) {
            document.getElementById("searchInput").focus()
        }
    }, [isShowSearchMenu])

    useEffect(() => {
        if (windowSize.width > 1185) {
            dispatch(setIsShowFilterMenu(false));
            setIsShowMobilemenu(false);
        }
    }, [windowSize])

    useEffect(() => {
        if (isShowFilterMenu) {
            setIsShowMobilemenu(true)
        }
    }, [isShowFilterMenu])

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const onSelectLang = (isocode2) => {
        dispatch(setLanguage(isocode2));
        setIsShowLanguageDropdown(false);
    };

    const setCategoryIds = (categoryId = null, subCategoryId = null) => {
        if (activeCategoryId === categoryId && !subCategoryId) {
            dispatch(setActiveCategoryId(null));
            return false;
        }
        dispatch(setActiveCategoryId(categoryId));
        dispatch(setActiveSubCategoryId(subCategoryId));
    }

    const closeMobileMenues = () => {
        setIsShowMobilemenu(!isShowMobilemenu);
        dispatch(setIsShowFilterMenu(false))
    }

    const scrollToCurrentDivPosition = (id, offset = 0) => {
        const targetElement = document.getElementById(id);
        if (targetElement) {
            const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = elementPosition + offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    if (!translations || !translations.length) {
        return false;
    }

    return (
        <div>
            <motion.header
                initial={{ y: -100 }}
                animate={{ y: 0 }}
                exit={{ y: -100 }}
                transition={{ duration: 1, delay: pathname === "/" ? 1 : 0 }} // Добавляем задержку в 1 секунду
            >
                <header className={`animate ${isActive ? "fixed" : ""}`}>
                    <div className="cont animate wow fadeInDown">

                        <div
                            className="search-block"
                            // ref={wrapperRef}
                            style={{ display: isShowSearchMenu ? "block" : "none" }}
                        >
                            <div className="search-block-layer"
                                onClick={() => setIsShowSearchMenu(false)}
                            />
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    console.log(searchValue);
                                    navigate(`/search?s=${searchValue}`);
                                    setIsShowSearchMenu(false);
                                    setSearchValue("")
                                }}
                                className="search-block-cont">
                                <input
                                    id="searchInput"
                                    name="s"
                                    placeholder={translate("TR_SEARCH_BY_AUTHORS_AND_TITLES")}
                                    type="text"
                                    value={searchValue}
                                    onChange={(event) => setSearchValue(event.target.value)}
                                />
                                <input className="btn" type="submit" value={translate("TR_SEARCH")} />
                                <div className="clear"></div>
                            </form>
                        </div>


                        <div
                            className={`c-hamburger c-hamburger--htx mobile ${isShowMobilemenu ? "is-active" : ""}`}
                            onClick={closeMobileMenues}>
                            <span>ru</span>
                        </div>

                        <Link
                            className="logo animate"
                            to="/"
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                            }}
                        ></Link>

                        <ul className="menu animate desktop">
                            <li>
                                <NavLink to="/">{translate("TR_MAIN")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/artists">{translate("TR_ARTISTS")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/store">{translate("TR_STORE")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/exhibitions">{translate("TR_EXHIBITIONS")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/blogs">{translate("TR_BLOG")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/library">{translate("TR_INVESTMENTS")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact">{translate("TR_CONTACT")}</NavLink>
                            </li>
                        </ul>
                        <div className="position-relative ln animate" style={{ position: "relative", cursor: "pointer" }}>
                            <Link
                                to="#"
                                onClick={() => {
                                    setIsShowLanguageDropdown(!isShowLanguageDropdown);
                                }}
                            >
                                {language}
                            </Link>
                            {
                                languages && languages.length ?
                                    <div className="language-dropdown" style={{ display: isShowLanguageDropdown ? "flex" : "none" }}>
                                        <ul>
                                            {
                                                languages.map((item, index) => {
                                                    return <div
                                                        key={index}
                                                        onClick={() => onSelectLang(item.isocode2)}
                                                    >
                                                        <li>
                                                            {item.isocode2}
                                                        </li>
                                                    </div>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    : null
                            }
                        </div>

                        <div
                            className={`search-cont ${isShowSearchMenu ? "open" : ""}`}
                            onClick={() => {
                                setIsShowSearchMenu(!isShowSearchMenu);
                            }}
                        >
                            <div className="search"></div>
                        </div>

                        <AuthComponent />

                        <div className="fav-icon animate" style={{ paddingRight: "47px", height: "27px" }}>
                            <span>
                                {
                                    wishListProducts && wishListProducts.length ?
                                        wishListProducts.length
                                        : 0
                                }
                            </span>
                            <div className="header_fav_cont"><div className="dd-list-cont">
                                <div className="dd-list">
                                    <div className="shadow-block">
                                        <Link className="btn btn-no-str btn-fav" to="/fav">
                                            {translate("TR_GO_TO_WISHLIST")}
                                        </Link>
                                    </div>
                                    <div
                                        className="scroll-block mCustomScrollbar _mCS_6 mCS_no_scrollbar">
                                        <div
                                            className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside">
                                            <div
                                                className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y custom-scrollbar"
                                                style={{ maxHeight: "100%", overflowY: "auto", padding: "0 15px" }}
                                            >
                                                {
                                                    wishListProducts && wishListProducts.length ?
                                                        wishListProducts.map((item, index) => {
                                                            return <div key={index} className="cart-item" style={{ marginLeft: 0 }}>

                                                                <Link className="image" to={`/artwork/${item.id}`}>
                                                                    <div className="image-cont" style={{ height: "85%" }}>
                                                                        {
                                                                            item.imagePath ?
                                                                                <img
                                                                                    src={item.imagePath}
                                                                                    style={{ height: "100%" }}
                                                                                />
                                                                                : item.imagePaths && item.imagePaths.length ?
                                                                                    item.imagePaths.map((el, index) => {
                                                                                        if (el.isMain) {
                                                                                            return <img
                                                                                                key={index}
                                                                                                src={el.filePath ? el.filePath : "images/our-images/no-image.png"}
                                                                                                style={{ height: "100%" }}
                                                                                            />
                                                                                        }
                                                                                    })
                                                                                    : null
                                                                        }

                                                                    </div>
                                                                </Link>
                                                                <div className="cart-text">
                                                                    <div style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        gap: 3,
                                                                    }}
                                                                    >
                                                                        <Link className="name" to={`/artwork/${item.id}`}>
                                                                            {MainService.getValueByLanguageId(item.names, languageId)}
                                                                        </Link>
                                                                        <div
                                                                            className="fav to-fav remove-fav"
                                                                            style={{ width: "40px", backgroundPosition: "right" }}
                                                                            onClick={() => {
                                                                                dispatch(removeProductFromWishList(item));
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <p>
                                                                        {MainService.getValueByLanguageId(item.artistNames, languageId)}
                                                                    </p>
                                                                    <div className="price">
                                                                        <b>{item.price ? item.price : 0} ₽</b>
                                                                    </div>
                                                                </div>
                                                                <div className="clear"></div>
                                                            </div>
                                                        })
                                                        : null
                                                }
                                                <div className="clear"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>





                        <div className="cart-icon animate" style={{ paddingRight: "47px", height: "27px" }}>
                            <span>
                                {
                                    cartProducts && cartProducts.length ? cartProducts.length : 0
                                }
                            </span>
                            <div className="header_cart_cont">
                                <div className="dd-list-cont">
                                    <div className="dd-list">
                                        <div className="shadow-block">
                                            <Link className="btn btn-no-str" to="/cart">
                                                {translate("TR_GO_TO_CART")}
                                            </Link>
                                        </div>
                                        <div
                                            className="scroll-block mCustomScrollbar _mCS_2">
                                            <div
                                                id="mCSB_2"
                                                className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                            >
                                                <div
                                                    className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y custom-scrollbar"
                                                    style={{ maxHeight: "100%", overflowY: "auto", padding: "0 15px" }}
                                                >

                                                    {
                                                        cartProducts && cartProducts.length ?
                                                            cartProducts.map((item, index) => {
                                                                return <div key={index} className="cart-item" style={{ marginLeft: 0 }}>
                                                                    <Link className="image" to={`/artwork/${item.id}`}>
                                                                        <div className="image-cont" style={{ height: "85%" }}>
                                                                            {
                                                                                item.imagePath ?
                                                                                    <img
                                                                                        src={item.imagePath}
                                                                                        style={{ height: "100%" }}
                                                                                    />
                                                                                    : item.imagePaths && item.imagePaths.length ?
                                                                                        item.imagePaths.map((el, index) => {
                                                                                            if (el.isMain) {
                                                                                                return <img
                                                                                                    key={index}
                                                                                                    src={el.filePath ? el.filePath : "images/our-images/no-image.png"}
                                                                                                    style={{ height: "100%" }}
                                                                                                />
                                                                                            }
                                                                                        })
                                                                                        : null
                                                                            }

                                                                        </div>
                                                                    </Link>
                                                                    <div className="cart-text">
                                                                        <div style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            gap: 3,
                                                                        }}
                                                                        >
                                                                            <Link className="name" to={`/artwork/${item.id}`}>
                                                                                {MainService.getValueByLanguageId(item.names, languageId)}
                                                                            </Link>
                                                                            <div
                                                                                className="remove remove-from-fart"
                                                                                style={{ width: "40px", backgroundPosition: "right" }}
                                                                                onClick={() => {
                                                                                    dispatch(removeProductFromCart(item));
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <p>
                                                                            {MainService.getValueByLanguageId(item.artistNames, languageId)}
                                                                        </p>
                                                                        <div className="price">
                                                                            <b>{item.price ? item.price : 0} ₽</b>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clear"></div>
                                                                </div>
                                                            })
                                                            : null
                                                    }

                                                    <div className="clear"></div>
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>


                    {
                        pathname.includes("/artist/") && artistData ?
                            <div className="artist-header animate">
                                <div className="cont">
                                    <div className="name">
                                        {MainService.getValueByLanguageId(artistData.names, languageId)}
                                    </div>
                                    <div className="links">
                                        <div className="links-list">
                                            {
                                                artistData.cataloguePath ?
                                                    <a className="pdf" href={artistData.cataloguePath} target="_blank">
                                                        {translate("TR_CATALOGUE")}
                                                    </a>
                                                    : null
                                            }
                                            {
                                                artistData.bookPath ?
                                                    <a className="pdf" href={artistData.bookPath} target="_blank">
                                                        {translate("TR_BOOK")}
                                                    </a>
                                                    : null
                                            }
                                            {
                                                artistData.webSite ?
                                                    <a href={artistData.webSite} target="_blank">
                                                        {translate("TR_WEBSITE")}
                                                    </a>
                                                    : null
                                            }
                                            {
                                                artistData.filePath || artistData.filmLink ?
                                                    <a href={artistData.filePath ? artistData.filePath : artistData.filmLink} target="_blank">
                                                        {translate("TR_FILM")}
                                                    </a>
                                                    : null
                                            }
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                    <div className="tags">
                                        <Link to="#" onClick={() => scrollToCurrentDivPosition("tab-1", -200)}>{translate("TR_WORKS")}</Link>
                                        <Link to="#" onClick={() => scrollToCurrentDivPosition("tab-2", -200)}>{translate("TR_ABOUT")}</Link>
                                        <Link to="#" onClick={() => scrollToCurrentDivPosition("tab-3", -180)}>{translate("TR_EXHIBITIONS")}</Link>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="clear"></div>
                                </div>
                            </div>
                            : null
                    }


                </header>
            </motion.header>
            <div className={`header-mobile ${isShowMobilemenu ? "d-block" : "d-none"}`} style={{ height: "100%" }}>
                <form onSubmit={(event) => event.preventDefault()} className="search">
                    <input name="s" placeholder={translate("TR_SEARCH")} type="text" />
                    <input className="btn" type="submit" value={translate("TR_SEARCH")} />
                    <div className="clear"></div>
                </form>
                <div className="catalog-menu" style={{ display: isShowFilterMenu ? "block" : "none" }}>
                    <div className="cont">
                        <h2>Filters</h2>
                        {
                            categories && categories.length ?
                                <ul>
                                    {
                                        categories.map((item, index) => {
                                            return <li key={item.id} className={activeCategoryId === item.id ? "open" : ""}>
                                                <Link
                                                    to={`/store/${item.id}`}
                                                    className={activeCategoryId === item.id ? "active" : ""}
                                                    onClick={() => {
                                                        setCategoryIds(item.id);
                                                        dispatch(setIsShowFilterMenu(false));
                                                        setIsShowMobilemenu(false);
                                                    }}
                                                >
                                                    {MainService.getValueByLanguageId(item.names, languageId)} {item.count ? `(${item.count})` : null}
                                                </Link>
                                                {
                                                    item.childCategories && item.childCategories.length && activeCategoryId === item.id ?
                                                        <ul className="mobile-menu-sub-categories-block custom-scrollbar">
                                                            {
                                                                item.childCategories.map((el) => {
                                                                    return <li
                                                                        key={el.id}
                                                                    >
                                                                        <Link
                                                                            to={`/store/${el.id}`}
                                                                            className={activeSubCategoryId === el.id ? "active" : ""}
                                                                            onClick={() => {
                                                                                setCategoryIds(item.id, el.id)
                                                                                dispatch(setIsShowFilterMenu(false));
                                                                                setIsShowMobilemenu(false);
                                                                            }}
                                                                        >
                                                                            {MainService.getValueByLanguageId(el.names, languageId)} {el.count ? `(${el.count})` : null}
                                                                        </Link>
                                                                    </li>
                                                                })
                                                            }
                                                        </ul>
                                                        : null
                                                }
                                            </li>
                                        })
                                    }
                                </ul>
                                : null
                        }
                        <Link
                            className="btn btn-no-str"
                            to="/store"
                            onClick={() => {
                                dispatch(setActiveCategoryId(null));
                                dispatch(setActiveSubCategoryId(null));
                                dispatch(setIsShowFilterMenu(false));
                                setIsShowMobilemenu(false);
                            }}
                        >
                            {translate("TR_RESET_FILTERS")}
                        </Link>
                    </div>
                    <div className="copy pull-left">
                        <div className="cont">
                            <div className="img"></div>
                            <span>{new Date().getFullYear()} © All rights reserved</span>
                            <div className="clear"></div>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <div className="image-cont">
                        <img src="images/main-page-menu.jpg" />
                    </div>
                </div>
                <ul className="menu">
                    <li>
                        <NavLink to="/" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_MAIN")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/artists" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_ARTISTS")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/store" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_STORE")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/exhibitions" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_EXHIBITIONS")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/blogs" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_BLOG")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/library" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_INVESTMENTS")}</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" onClick={() => { setIsShowMobilemenu(false) }}>{translate("TR_CONTACT")}</NavLink>
                    </li>
                </ul>
                <div className="soc pull-right">
                    <div className="cont">
                        {/* <a className="yb" href="https://youtube.com/user/FormAndBronze" target="_blank">
                            <i></i>
                            <span>YouTube</span>
                        </a> */}
                        <a className="fb" href="https://www.facebook.com/profile.php?id=61553036567768&mibextid=LQQJ4d&mibextid=LQQJ4d" target="_blank">
                            <i></i>
                            <span>Facebook</span>
                        </a>
                        <a className="insta" href="https://www.instagram.com/formandseasons/profilecard/?igsh=eWtudW8xODB0djg5" target="_blank">
                            <i></i>
                            <span>Instagram</span>
                        </a>
                    </div>
                </div>
                <div className="copy pull-left">
                    <div className="cont">
                        <div className="img"></div>
                        <span>{new Date().getFullYear()} © All rights reserved</span>
                        <div className="clear"></div>
                    </div>
                </div>
            </div>


        </div>
    )
}