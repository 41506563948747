import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useTranslation } from "../lang/TranslationProvider";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import moment from "moment";
import parse from 'html-react-parser';

const Exhibition = () => {

	const { translate } = useTranslation();
	const { itemId } = useParams();
	const [exhibitionData, setExhibitionData] = useState(null);
	const [languageId, setLanguageId] = useState(null);
	const [exhibitions, setExhibitions] = useState([]);
	const [images, setImages] = useState([]);
	const { language, languages } = useSelector(state => state.language);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getExhibitionById();
		getExhibitions(1);
	}, [itemId])

	const getExhibitionById = () => {
		if (!itemId) {
			return false;
		}
		setExhibitionData(null);
		MainService.callApi({
			api: ApiService.getExhibitionById,
			data: itemId,
			cb: (response) => {
				if (response && response.data) {
					const data = { ...response.data };
					setExhibitionData(data);
					if (data.imagePaths && data.imagePaths.length) {
						setImages(data.imagePaths.sort((a, b) => b.isMain - a.isMain))
					} else {
						setImages([{ filePath: "images/our-images/no-image.png" }])
					}
				}
			},
		});
	};

	const getExhibitions = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
		}
		setExhibitions([]);
		MainService.callApi({
			api: ApiService.getExhibitions,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setExhibitions(data.pagedData);
					} else {
						setExhibitions([]);
					}
				}
			},
		});
	}

	const sliderRef = useRef(null);
	const [activeImageIndex, setActiveImageIndex] = useState(1);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current || activeImageIndex === 1) return;
		sliderRef.current.swiper.slidePrev();
		setActiveImageIndex(activeImageIndex - 1)
	}, [activeImageIndex]);

	const handleNext = useCallback(() => {
		if (!sliderRef.current || activeImageIndex === images.length) return;
		sliderRef.current.swiper.slideNext();
		setActiveImageIndex(activeImageIndex + 1)
	}, [activeImageIndex]);

	const handleSlideChange = useCallback(() => {
		if (!sliderRef.current) return;
		setActiveImageIndex(sliderRef.current.swiper.realIndex + 1);
	}, []);

	return <div className="cont">
		<Link className="back-btn" to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
		<div className="exhibition-top-block">
			{/* <Link className="calendar mobile" to="#">{translate("TR_ADD_TO_CALENDAR")}</Link> */}
			{
				exhibitionData ?
					<React.Fragment>
						<div className="exhibition-item">
							<div className="date">
								<b>{moment(new Date(exhibitionData.startDate)).format("DD.MM.YYYY")}</b>
								<span></span>
								<b>{moment(new Date(exhibitionData.endDate)).format("DD.MM.YYYY")}</b>
								<div className="clear"></div>
							</div>
							<div className="image">
								<div className="image-cont exhibition-page-carousel">
									<Swiper
										ref={sliderRef}
										onSlideChange={handleSlideChange}
									>
										{
											images.map((item, index) => {
												return <SwiperSlide key={index}><img src={item.filePath} /></SwiperSlide>
											})
										}
									</Swiper>
								</div>
							</div>
							<div className="nav">
								<div className="prev" onClick={handlePrev}></div>
								<span size="32">{activeImageIndex}/{images.length}</span>
								<div className="next" onClick={handleNext}></div>
								<div className="clear"></div>
							</div>
						</div>
						{/* <Link className="calendar desktop" to="#">{translate("TR_ADD_TO_CALENDAR")}</Link> */}
						<div className="line"></div>
						<h1 className="title">{MainService.getValueByLanguageId(exhibitionData.names, languageId)}</h1>
						<div className="short">{MainService.getValueByLanguageId(exhibitionData.shortDescriptions, languageId)}</div>
						<div className="line"></div>

						<div className="small-artist-list">
							{
								exhibitionData.artists && exhibitionData.artists.length ?
									exhibitionData.artists.map((item, index) => {
										return <div key={index}>
											<Link className="item" to={`/artist/${item.id}`}>
												<div className="image">
													<div className="image-cont" style={{ height: "100%" }}>
														<img style={{ height: "76%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
													</div>
												</div>
												<div className="name">{MainService.getValueByLanguageId(item.names, languageId)}</div>
												<div className="clear"></div>
											</Link>
											<br />
										</div>
									})
									: null
							}


						</div>
						<div className="text">
							{parse(MainService.getValueByLanguageId(exhibitionData.descriptions, languageId))}
						</div>
					</React.Fragment>
					: null
			}

			<div className="clear"></div>
		</div>
		<div className="exhibitions-list">
			{/* <div className="title title-small mobile">Other exhibitions</div> */}
			<div className="title title-small mobile">{translate("TR_OTHER_EXHIBITIONS")}</div>
			<div className="line mobile"></div>
			<div className="exhibition-item-cont mobile">
				<div className="exhibition-item-cont-block" mobile_width="9600">
					{
						exhibitions && exhibitions.length && exhibitions.filter(item => item.id !== +itemId).length ?
							exhibitions.filter(item => item.id !== +itemId).map((item, index) => {
								return <div key={index} className="exhibition-item">
									<div className="date">
										<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
										<span></span>
										<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
										<div className="clear"></div>
									</div>
									<div className="image">
										<Link className="image-cont" to={`/exhibition/${item.id}`}>
											<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
										</Link>
									</div>
									<Link className="name" to={`/exhibition/${item.id}`}>
										{MainService.getValueByLanguageId(item.names, languageId)}
									</Link>
									<div className="line"></div>
									<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
								</div>
							})
							: null
					}
					<div className="clear"></div>
				</div>
			</div>
			<div className="column pull-left desktop">
				<div className="title title-small desktop">{translate("TR_OTHER_EXHIBITIONS")}</div>
				<div className="line"></div>
				{
					exhibitionData && exhibitions && exhibitions.length && exhibitions.filter(item => item.id !== +itemId).length ?
						exhibitions.filter(item => item.id !== +itemId).map((item, index) => {
							if (index % 2 === 0) {
								return <div key={index} className="exhibition-item">
									<div className="date">
										<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
										<span></span>
										<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
										<div className="clear"></div>
									</div>
									<div className="image">
										<Link className="image-cont" to={`/exhibition/${item.id}`}>
											<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
										</Link>
									</div>
									<Link className="name" to={`/exhibition/${item.id}`}>
										{MainService.getValueByLanguageId(item.names, languageId)}
									</Link>
									<div className="line"></div>
									<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
								</div>
							}
						})
						: null
				}
			</div>
			<div className="column pull-right desktop">
				{
					exhibitions && exhibitions.length && exhibitions.filter(item => item.id !== +itemId).length ?
						exhibitions.filter(item => item.id !== +itemId).map((item, index) => {
							if (index % 2 === 1) {
								return <div key={index} className="exhibition-item">
									<div className="date">
										<b>{moment(new Date(item.startDate)).format("DD.MM.YYYY")}</b>
										<span></span>
										<b>{moment(new Date(item.endDate)).format("DD.MM.YYYY")}</b>
										<div className="clear"></div>
									</div>
									<div className="image">
										<Link className="image-cont" to={`/exhibition/${item.id}`}>
											<img style={{ height: "100%" }} src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} />
										</Link>
									</div>
									<Link className="name" to={`/exhibition/${item.id}`}>
										{MainService.getValueByLanguageId(item.names, languageId)}
									</Link>
									<div className="line"></div>
									<span>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</span>
								</div>
							}
						})
						: null
				}
			</div>
			<div className="clear"></div>
		</div>
	</div>
}

export default Exhibition