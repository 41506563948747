import React, { useState } from "react";
import MainService from "../../helpers/services/mainService";
import { ApiService } from "../../helpers/services/apiSrevice";
import AlertService from "../../helpers/services/alertService";
import { useTranslation } from "../../lang/TranslationProvider";

const Letter = ({ isShowModal, setIsShowModal }) => {

    const { translate } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({
        name: "",
        email: "",
        message: "",
    });

    const checkButtonIsDisabled = () => {
        if (!isLoading && values.name.trim().length && values.email.trim().length && values.message.trim().length) {
            return false;
        } else {
            return true
        }
    }

    const onSubmit = (event) => {
        event && event.preventDefault()
        setIsLoading(true);
        MainService.callApi({
            api: ApiService.sendLetter,
            data: values,
            cb: () => {
                // AlertService.alert("success", "The letter was sent successfully");
                alert("The letter was sent successfully")
            },
            _finally: (() => {
                setIsLoading(false);
                setIsShowModal(false);
                setValues({
                    name: "",
                    email: "",
                    message: "",
                })
            })
        });
    };

    if (!isShowModal) {
        return false
    }
    return (
        <React.Fragment>
            <div
                className="modal-hidden"
                style={{ display: "block" }}
                onClick={() => {
                    setIsShowModal(false);
                }}
            />

            <div className="modal" style={{ display: "block" }}>
                <div
                    className="close"
                    onClick={() => {
                        setIsShowModal(false);
                    }}
                />
                <form onSubmit={onSubmit}>
                    <div className="modal-cont" style={{ padding: "40px" }}>
                        <div className="title">{translate("TR_WRITE_A_LETTER")}</div>
                        <div className="line"></div>
                        <div style={{ display: "flex" }}>
                            <input
                                required name="name"
                                placeholder={`${translate("TR_NAME")}*`}
                                type="text"
                                style={{ width: "100%" }}
                                value={values.name}
                                onChange={(event) => setValues((values) => ({ ...values, name: event.target.value }))}
                            />
                            <input
                                required
                                name="email"
                                placeholder={`${translate("TR_EMAIL")}*`}
                                type="text"
                                style={{ width: "100%", marginRight: "6px" }}
                                value={values.email}
                                onChange={(event) => setValues((values) => ({ ...values, email: event.target.value }))}
                            />
                        </div>
                        <div className="clear"></div>
                        <div className="clear"></div>
                        <textarea
                            required
                            name="message"
                            placeholder={`${translate("TR_MESSAGE")}*`}
                            style={{ width: "100%" }}
                            value={values.message}
                            onChange={(event) => setValues((values) => ({ ...values, message: event.target.value }))}
                        />
                        <button
                            style={{ cursor: "pointer" }}
                            className="btn"
                            type="submimt"
                            disabled={checkButtonIsDisabled()}
                        >
                            {translate("TR_SEND_A_LETTER")}
                        </button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Letter;