import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../scrollToTop/ScrollToTop";


export default function Layout() {

	return (
		<div>
			<ScrollToTop>
				<Header />
				<Outlet />
				<Footer />
			</ScrollToTop>
		</div>
	)
}