import axios from "axios";
import store from "./../../store/index";
import { setToken, setLogout } from "../../store/reducers/userReducer";
import { API_URL_KEY } from "../constants/apiKeys";

const api = axios.create({
	baseURL: API_URL_KEY,
	withCredentials: false,
});

api.interceptors.request.use(async config => {
	config.headers = {
		Authorization: localStorage.getItem("token")
			? localStorage.getItem("token")
			: "",
		"ngrok-skip-browser-warning": "true",
		"Access-Control-Allow-Origin": "true",
		'language': localStorage.getItem("language") ? localStorage.getItem("language") : "en",
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data && response.data?.status >= 300 && response.data?.status < 400) {
			return Promise.reject("Bad request");
		}
		if (response && response.data && response.data?.status >= 400 && response.data?.status < 500) {
			return Promise.reject("Bad request");
		}
		if (response && response.data && response.data?.status >= 500) {
			if (response.data?.message) {
				return Promise.reject(response.data?.message || "Internal Server Error");
			}
			return Promise.reject("Sorry, something went wrong!");
		}

		return Promise.resolve(response?.data)
	},
	error => {
		if (error.code === "ERR_NETWORK") {
			if (localStorage.getItem("user") || localStorage.getItem("token")) {
				logout();
			}
			return;
		}
		if (error.response) {
			if (error.response.status === 401) {
				// return Promise.reject("401 TIGO");
				if (localStorage.getItem("user") || localStorage.getItem("token")) {
					logout();
				}
			}
			const _error = error.response.data.message;
			return Promise.reject(_error || "");
		} else {
			return Promise.reject("Unrecognized error")
		}
	},
);

const logout = () => {
	localStorage.clear();
	window.location.reload();
	store.dispatch(setLogout(null));
	store.dispatch(setToken(""));
};

class ApiService {
	static getLanguages() {
		return api.get(`/api/v0/Language/GetActiveLanguages`);
	};
	static getAllTranslations() {
		return api.get(`/api/v0/Translation/GetByLanguageIsoCode`);
	};
	static getCurrentUser() {
		return api.get(`/api/v0/User/Current`);
	}
	static getAllCategories() {
		return api.get(`/api/v2/Category/GetThree`);
	}
	static getArtworks(data) {
		if (data.categoryId && data.sortingTypeId) {
			return api.post(`/api/v2/Artwork/GetStoreList?categoryId=${data.categoryId}&sortingLibraryDataId=${data.sortingTypeId}`, data);
		} else if (data.categoryId) {
			return api.post(`/api/v2/Artwork/GetStoreList?categoryId=${data.categoryId}`, data);
		} else if (data.sortingTypeId) {
			return api.post(`/api/v2/Artwork/GetStoreList?sortingLibraryDataId=${data.sortingTypeId}`, data);
		} else {
			return api.post(`/api/v2/Artwork/GetStoreList`, data);
		}
	}
	static getLibraryData(name) {
		return api.get(`/api/v0/Library/GetByName?name=${name}`);
	};
	static getBlogCategories() {
		return api.get(`/api/v2/Blog/GetCategories`);
	}
	static getBlogs(data) {
		if (data.categoryId) {
			return api.post(`/api/v2/Blog/GetList?categoryId=${data.categoryId}`, data);
		} else {
			return api.post(`/api/v2/Blog/GetList`, data);
		}
	}
	static getBlogById(id) {
		return api.get(`/api/v2/Blog/Get?id=${id}`);
	}
	static getContacts() {
		return api.get(`/api/v2/Contact/GetAll`);
	}
	static getExhibitionFilters() {
		return api.post(`/api/v2/Exhibition/GetFilter`);
	}
	static getExhibitions(data) {
		if (data.exhibitionType) {
			return api.post(`/api/v2/Exhibition/GetList?exhibitionType=${data.exhibitionType}`, data);
		} else {
			return api.post(`/api/v2/Exhibition/GetList`, data);
		}
	}
	static getExhibitionById(id) {
		return api.get(`/api/v2/Exhibition/Get?id=${id}`);
	}
	static getInvestments(data) {
		return api.post(`/api/v2/Investment/GetList`, data);
	}
	static getInvestmentById(id) {
		return api.get(`/api/v2/Investment/Get?id=${id}`);
	}
	static getPageContentByKeyName(keyName) {
		return api.get(`/api/v2/PageContent/GetByKeyName?keyName=${keyName}`);
	}
	static sendLetter(data) {
		return api.post(`/api/v2/Contact/CreateMessage`, data);
	}
	static getArtists(data) {
		if (data.categoryId) {
			return api.post(`/api/v2/Artist/GetList?categoryId=${data.categoryId}&artworksCount=${data.artworksCount}`, data);
		} else {
			return api.post(`/api/v2/Artist/GetList?artworksCount=${data.artworksCount}`, data);
		}
	}
	static getArtistAllCategories() {
		return api.get(`/api/v2/Artist/GetCategories`);
	}
	static getArtistById(id) {
		return api.get(`/api/v2/Artist/Get?id=${id}`);
	}

	static getExhibitionsByArtistId(data) {
		return api.post(`/api/v2/Exhibition/GetByArtist?artistId=${data.artistId}`, data);
	}
	static getArtworksByArtistId(data) {
		if (data.categoryId) {
			return api.post(`/api/v2/Artwork/GetListByArtist?artistId=${data.artistId}&categoryId=${data.categoryId}`, data);
		} else {
			return api.post(`/api/v2/Artwork/GetListByArtist?artistId=${data.artistId}`, data);
		}
	}
	static getArtistCategories(id) {
		return api.post(`/api/v2/Artwork/GetCategoriesByArtist?artistId=${id}`);
	}
	static getSearchedData(data) {
		return api.post(`/api/v2/Home/Search?searchText=${data.searchText}`, data);
	}
	static getArtworkById(id) {
		return api.get(`/api/v2/Artwork/Get?id=${id}`);
	}
	static getAtristWorksById(data) {
		return api.post(`/api/v2/Artwork/GetListByArtist?artistId=${data.artistId}`, data);
	}
	static getPageContentsByKeyNames(data) {
		return api.post(`/api/v2/PageContent/GetByKeyNames`, data);
	}
	static getCartProducts(data) {
		return api.post(`/api/v2/Cart/Get`, data);
	}
	static userRegistration(data) {
		return api.post(`/api/v0/User/Signup`, data);
	}
	static updateAvatar(data) {
		return api.put(`/api/v0/User/UpdateAvatar`, data);
	}
	static updateUserData(data) {
		return api.put(`/api/v0/User/Update`, data);
	}
	static updateUserAddress(data) {
		return api.put(`/api/v2/Address/Update`, data);
	}
	static deleteAvatar() {
		return api.delete(`/api/v0/User/DeleteAvatar`);
	}
	static makeOrder(data) {
		return api.post(`/api/v2/Order/Create`, data);
	}
	static getOrders(data) {
		return api.post(`/api/v2/Order/GetList`, data);
	}
	static getOrderById(id) {
		return api.get(`/api/v2/Order/Get?id=${id}`);
	}
}

export { ApiService, logout };
