import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MainService from "../helpers/services/mainService";
import { ApiService } from "../helpers/services/apiSrevice";
import { useTranslation } from "../lang/TranslationProvider";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';

const Investment = () => {

	const { translate } = useTranslation();
	const { itemId } = useParams();
	const [investmentData, setInvestmentData] = useState(null);
	const [investments, setInvestments] = useState([]);
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	useEffect(() => {
		getInvestmentById();
		getInvestments(1);
	}, [itemId])

	const getInvestmentById = () => {
		if (!itemId) {
			return false;
		}
		setInvestmentData(false);
		MainService.callApi({
			api: ApiService.getInvestmentById,
			data: itemId,
			cb: (response) => {
				if (response && response.data) {
					setInvestmentData(response.data);
				}
			},
		});
	};

	const getInvestments = (currentPage) => {
		const data = {
			pageNumber: currentPage,
			pageSize: 10,
		}
		MainService.callApi({
			api: ApiService.getInvestments,
			data: data,
			cb: response => {
				if (response && response.data) {
					const data = { ...response.data };
					if (data.pagedData && data.pagedData.length) {
						setInvestments(data.pagedData);
					} else {
						setInvestments([]);
					}
				}
			},
		});
	}

	return (

		<section className="cont">
			<Link className="back-btn" to="#" onClick={() => window.history.back()}>{translate("TR_BACK")}</Link>
			{
				investmentData ?
					<React.Fragment>
						<div className="main-page-bunner exhibitions-page-bunner blog-page-bunner">
							<div className="exhibition-item blog-item pull-left">
								<div className="image">
									<div className="image-cont">
										{
											investmentData.imagePath ?
												<img src={investmentData.imagePath.filePath} alt="image" style={{ height: "100%" }} />
												: <img src="images/our-images/no-image.png" style={{ height: "100%" }} />
										}
									</div>
								</div>
							</div>
							<div className="text pull-right">
								<span className="label">{translate("TR_INVESTMENTS")}</span>
								<div className="line"></div>
								<h1 className="name">{MainService.getValueByLanguageId(investmentData.names, languageId)}</h1>
								<div className="text-block">
									<p>{MainService.getValueByLanguageId(investmentData.shortDescriptions, languageId)}</p>
								</div>
								<div className="clear"></div>
								{
									investmentData.filePath ?
										// <div className="artist-page-bunner investment-page-bunner " style={{ padding: 0 }}>
										// 	<div className="links" style={{ paddingLeft: 0 }}>
										// 		<div className="links-list">
										// 			<a className="pdf" href={investmentData.filePath.filePath} target="_blank">
										// 				{translate("TR_BOOK")}
										// 			</a>
										// 			<div className="clear"></div>
										// 		</div>
										// 	</div>

										// </div>

										// .big-catalog .cat-list .list a

										<div className="big-catalog">
											<div className="cat-list">
												<div className="list">
													<a href={investmentData.filePath.filePath} className="active" target="_blank">
														{translate("TR_READ_ONLINE")}
													</a>
												</div>
											</div>
										</div>
										: null
								}
							</div>
							<div className="clear"></div>
							<div className="bottom-text">
								{parse(MainService.getValueByLanguageId(investmentData.descriptions, languageId))}
							</div>
						</div>
					</React.Fragment>
					: null
			}

			<div className="exhibitions-list exhibitions-list-margin blogs-list-margin blogs-list-margin-no-shadow">
				{
					investmentData && investments && investments.length && investments.filter(item => item.id !== +itemId).length ?
						<div className="title title-small mobile">{translate("TR_OTHER_INVESTMENT_ENTRIES")}</div>
						: null
				}
				<div className="exhibition-item-cont mobile">
					<div className="exhibition-item-cont-block">
						{
							investments && investments.length && investments.filter(item => item.id !== +itemId).length ?
								investments.filter(item => item.id !== +itemId).map((item, index) => {
									return <div key={index} className="exhibition-item blog-item">
										<div className="image">
											<Link
												className="image-cont"
												to={`/library-item/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} />
											</Link>
										</div>
										<Link
											className="name"
											to={`/library-item/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								})
								: null
						}
						<div className="clear"></div>
					</div>
				</div>
				<div className="column pull-left desktop">
					{
						investmentData && investments && investments.length && investments.filter(item => item.id !== +itemId).length ?
							<div className="title title-small">{translate("TR_OTHER_INVESTMENT_ENTRIES")}</div>
							: null
					}
					{
						investments && investments.length && investments.filter(item => item.id !== +itemId).length ?
							investments.filter(item => item.id !== +itemId).map((item, index) => {
								if (index % 2 === 0) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="image">
											<Link
												className="image-cont"
												to={`/library-item/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} />
											</Link>
										</div>
										<Link
											className="name"
											to={`/library-item/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}

				</div>
				<div className="column pull-right desktop">
					{
						investments && investments.length && investments.filter(item => item.id !== +itemId).length ?
							investments.filter(item => item.id !== +itemId).map((item, index) => {
								if (index % 2 === 1) {
									return <div key={index} className="exhibition-item blog-item">
										<div className="image">
											<Link
												className="image-cont"
												to={`/library-item/${item.id}`}
											>
												<img src={item.imagePath ? item.imagePath : "images/our-images/no-image.png"} style={{ height: "100%" }} />
											</Link>
										</div>
										<Link
											className="name"
											to={`/library-item/${item.id}`}
										>
											{MainService.getValueByLanguageId(item.names, languageId)}
										</Link>
										<div className="line"></div>
										<p>{MainService.getValueByLanguageId(item.shortDescriptions, languageId)}</p>
									</div>
								}
							})
							: null
					}

				</div>
				<div className="clear"></div>
			</div>


		</section>
	)
}

export default Investment