import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  translations: [],
};

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    setTranslations(state, action) {
      state.translations = action.payload;
    },
  },
});

export const { setTranslations } = translationSlice.actions;
const translationReducer = translationSlice.reducer;

export default translationReducer;
