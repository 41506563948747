import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../lang/TranslationProvider";
import { Link } from "react-router-dom";
import { addProductsToCart, removeProductFromCart, removeProductsFromCart } from "../store/reducers/productReducer";
import MainService from "../helpers/services/mainService";
import moment from "moment";
import { ApiService } from "../helpers/services/apiSrevice";

const CartItem = ({ item, languageId, onGiftChange, onCommentChange }) => {
	const [currentRel, setCurrentRel] = useState(0);
	const { translate } = useTranslation();
	const images = item.imagePaths || [];

	const handlePrev = () => {
		setCurrentRel((prevRel) => (prevRel > 0 ? prevRel - 1 : images.length - 1));
	};

	const handleNext = () => {
		setCurrentRel((prevRel) => (prevRel < images.length - 1 ? prevRel + 1 : 0));
	};

	const dispatch = useDispatch();

	return (
		<div className="cart-item">
			<div className="remove remove-from-cart" onClick={() => dispatch(removeProductFromCart(item))} />
			<div className="cart-image pull-left" current_rell={currentRel} max_rell={images.length - 1}>
				<Link className="image" to={`/artwork/${item.id}`}>
					<div className="image-cont">
						{images.map((src, index) => (
							<img key={index} rel={index} src={src.filePath} style={{ height: "100%" }} />
						))}
					</div>
				</Link>
				<div className="nav">
					<div className="prev" onClick={handlePrev}></div>
					{images.map((_, index) => (
						<span
							key={index}
							className={currentRel === index ? 'active' : ''}
							rel={index}
							onClick={() => setCurrentRel(index)}
						></span>
					))}
					<div className="next" onClick={handleNext}></div>
					<div className="clear"></div>
				</div>
			</div>
			<div className="cart-text">
				<Link className="name" to={`/artwork/${item.id}`}>{MainService.getValueByLanguageId(item.names, languageId)}</Link>
				<div className="short">{MainService.getValueByLanguageId(item.artistNames, languageId)}</div>
				<div className="line"></div>
				<div className="small-text">
					<span>
						<p style={{ display: "flex" }}>
							{
								item.materials && item.materials.length ?
									item.materials.map((el, idx) => {
										return <span key={idx}>{translate(el)}{`${item.materials.length === idx + 1 ? "" : ","}`}</span>
									})
									: null
							}
						</p>
						<p>{item.size} {translate("TR_SM")}, {moment(item.productionDate).format("YYYY")} {translate("TR_YEAR")}</p>
					</span>
				</div>
				<div className="line"></div>
				<div className="price">
					{item.oldPrice && <b className="old-price">{item.oldPrice} ₽</b>}
					<b>{item.price} ₽</b>
				</div>
				<input
					id={`cart-item-${item.id}`}
					name={`cart-item-${item.id}`}
					type="checkbox"
					checked={item.isGift}
					onChange={(e) => onGiftChange(item.id, e.target.checked)}
				/>
				<label htmlFor={`cart-item-${item.id}`}>{translate("TR_THIS_ARTWORK_IS_A_GIFT_MSG")}</label>
			</div>
			<div className="clear"></div>
			<textarea
				rows
				name="comment"
				placeholder={translate("TR_TYPE_YOUR_MESSAGE_HERE")}
				value={item.giftMessage}
				onChange={(e) => onCommentChange(item.id, e.target.value, 2000)}
			></textarea>
		</div>
	);
};

export default function ShoppingCart() {
	const dispatch = useDispatch();
	const { translate } = useTranslation();
	const { cartProducts } = useSelector(state => state.product);
	const [languageId, setLanguageId] = useState(null);
	const { language, languages } = useSelector(state => state.language);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		getCartProducts();
	}, [])

	const getCartProducts = () => {
		if (!cartProducts || !cartProducts.length) {
			return false;
		}
		let ids = cartProducts.map(product => product.id);

		if (!ids.length) {
			return false;
		}

		setIsLoading(true);

		MainService.callApi({
			api: ApiService.getCartProducts,
			data: ids,
			cb: response => {
				if (response && response.data && response.data.length) {
					let data = [...response.data.filter(el => el.price)];
					if (data && data.length) {
						data.forEach(el => {
							const oldProduct = cartProducts.find(item => item.id === el.id);
							if (oldProduct) {
								el.giftMessage = oldProduct.giftMessage || "";
								el.isGift = oldProduct.isGift || false;
							} else {
								el.giftMessage = "";
								el.isGift = false;
							}
						});
						dispatch(addProductsToCart(data));
					} else {
						dispatch(removeProductsFromCart());
					}
				} else {
					dispatch(removeProductsFromCart());
				}
			},
			_finally: () => {
				setIsLoading(false)
			}
		});
	};

	useEffect(() => {
		if (languages && languages.length && language) {
			setLanguageId(languages.find(item => item.isocode2 === language)?.id);
		}
	}, [languages, language]);

	const calculateTotalPrice = (products) => {
		return products.reduce((total, product) => total + product.price, 0);
	};

	const calculateDiscountedPrice = (products, discountPercentage) => {
		return products.reduce((total, product) => total + product.price, 0) * (1 - discountPercentage / 100);
	}

	const handleGiftChange = (id, isGift) => {
		const updatedProducts = cartProducts.map(product =>
			product.id === id ? { ...product, isGift } : product
		);
		dispatch(addProductsToCart(updatedProducts));
	};

	const handleCommentChange = (id, giftMessage, maxLength = Infinity) => {
		console.log(giftMessage);
		if (giftMessage.length > maxLength) { return false; }
		const updatedProducts = cartProducts.map(product =>
			product.id === id ? { ...product, giftMessage } : product
		);
		dispatch(addProductsToCart(updatedProducts));
	};

	return (
		<div className="cont">
			<Link className="back-btn" to="store">{translate("TR_BACK")}</Link>
			{
				isLoading ? null :
					<div className="cart">
						<div className="cart-left pull-left">
							{
								cartProducts && cartProducts.length ?
									cartProducts.map((item, index) => (
										<CartItem
											key={index}
											item={item}
											languageId={languageId}
											onGiftChange={handleGiftChange}
											onCommentChange={handleCommentChange}
										/>
									))
									: <p>{translate("TR_NO_PRODUCTS")}</p>
							}
						</div>
						<div className="cart-right pull-right">
							<div className="cart-panel">
								<div className="title">{translate("TR_BASKET_TOTALS")}</div>
								<div className="cart-line">
									<span>{translate("TR_SUB_TOTAL")}</span>
									<div className="price price-old">
										<b>{calculateTotalPrice(cartProducts)} ₽</b>
									</div>
									<div className="clear"></div>
								</div>
								<div className="total">
									<div className="left">{translate("TR_TOTAL")}</div>
									<div className="right">{calculateDiscountedPrice(cartProducts, 5)} ₽</div>
									{
										cartProducts && cartProducts.length ?
											<div className="clear"></div>
											: null
									}
								</div>
								{
									cartProducts && cartProducts.length ?
										<Link
											className="btn btn-no-str btn-order"
											to="/order"
										>
											{translate("TR_PROCEED_TO_CHECKOUT")}
										</Link>
										: null
								}
							</div>
						</div>
						<div className="clear"></div>
					</div>
			}
		</div>
	);
}
