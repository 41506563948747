import axios from "axios";
import { IDENTITY_API_URL_KEY } from "../constants/apiKeys";

const identityApi = axios.create({
	baseURL: IDENTITY_API_URL_KEY,
});

class IdentityService {

	static userLogin(data) {
		return identityApi.post(`/identity/GetPWDAccessToken`, data);
	}

}

export default IdentityService;
