import React from "react";
import { ToastContainer } from "react-toastify";
import { router } from "./pages/router";
import { RouterProvider } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

const App = () => {

  return <div style={{ maxWidth: "100vw", overflow: "hidden" }}>

    <RouterProvider router={router()} />
    <ToastContainer theme="dark" autoClose={5000} />

  </div>
}
export default App