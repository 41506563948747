import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "../../../lang/TranslationProvider";
import { Link } from "react-router-dom";
import MainService from "../../../helpers/services/mainService";
import { ApiService } from "../../../helpers/services/apiSrevice";
import { setCurrentUser, setLogout, setToken } from "../../../store/reducers/userReducer";
import IdentityService from "../../../helpers/services/identityService copy";


const AuthComponent = () => {

    const dispatch = useDispatch();
    const wrapperRef = useRef(null);
    const { translate } = useTranslation();
    const { user } = useSelector(state => state.user);
    const [isShowLoginModal, setIsShowLoginModal] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [loginFields, setLoginFields] = useState({
        userName: "",
        password: "",
    })
    const [registerFields, setRegisterFields] = useState({
        userName: "",
        password: "",
        email: "",
    })

    const onChange = (event, fieldName, cb, maxHeight = Infinity) => {
        const { value } = event.target;
        if (value.length >= maxHeight) {
            return false;
        }
        if (fieldName === "userName") {
            const newValue = value.replace(/\s/g, '');
            cb(prev => ({
                ...prev,
                [fieldName]: newValue
            }))
        } else {
            cb(prev => ({
                ...prev,
                [fieldName]: value
            }))
        }
    }

    const userRegistration = async (event) => {
        event && event.preventDefault()
        if (!registerFields.userName.trim().length || !registerFields.password.trim().length || !registerFields.email.trim().length) {
            return false;
        }
        setIsLoading(true);
        const registerFieldsCopy = { ...registerFields };
        registerFieldsCopy.password = window.btoa(registerFieldsCopy.password);
        MainService.callApi({
            api: ApiService.userRegistration,
            data: registerFieldsCopy,
            cb: response => {
                if (response && response.success) {
                    userLogin(null, { userName: registerFields.userName, password: registerFields.password });
                }
            },
            getFail: () => {
                setIsLoading(false);
            }
        });
    }

    const userLogin = async (event, data) => {
        event && event.preventDefault();
        const _loginFields = data ? { ...data } : { ...loginFields }
        if (!_loginFields.userName.trim().length || !_loginFields.password.trim().length) {
            return false;
        }
        setIsLoading(true);
        MainService.callApi({
            api: IdentityService.userLogin,
            data: _loginFields,
            cb: response => {
                if (response && response.data && response.data.accessToken) {
                    const token = response.data.accessToken;
                    dispatch(setToken(token));
                    setTimeout(() => {
                        getCurrentUser();
                    }, 100);
                } else {
                    throw new Error('Invalid E-mail or Password.');
                }
            },
            getFail: () => {
                setIsLoading(false);
            }
        });
    }

    const getCurrentUser = async () => {
        MainService.callApi({
            api: ApiService.getCurrentUser,
            cb: response => {
                if (response && response.data) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                    dispatch(setCurrentUser(response.data));
                } else {
                    dispatch(setToken(null));
                }
            },
            _finally: () => {
                setIsLoading(false);
                setIsShowModal(false);
            }
        });
    }

    const setModal = () => {
        if (user) {
            return <div>
                <div className="dd-list" style={{ width: "300px" }}>
                    <div className="user-top">
                        <div className="image" style={{ display: "flex" }}>
                            <img
                                style={{ width: "100%" }}
                                src={user.avatarPath ? user.avatarPath : "images/our-images/no-image.png"}
                            />
                        </div>
                        <div className="name">{user?.firstName} {user?.lastName}</div>
                        <div className="clear"></div>
                    </div>
                    <div className="user-bottom">
                        <div className="sale">{translate("TR_YOUR_DISCOUNT")} 5%</div>
                        <Link
                            className="btn btn-no-str btn-edit"
                            to="/orders"
                            onClick={() => {
                                setTimeout(() => {
                                    setIsShowModal(false);
                                }, 1);
                            }}
                        >
                            <img src="images/edit.png" height={17} />
                            <span>{translate("TR_ORDERS")}</span>
                        </Link>
                        <Link
                            className="btn btn-no-str btn-edit"
                            to="/profile"
                            onClick={() => {
                                setTimeout(() => {
                                    setIsShowModal(false);
                                }, 1);
                            }}
                        >
                             <img src="images/edit.png" height={17} />
                            <span>{translate("TR_EDIT")}</span>
                        </Link>
                        <Link
                            className="btn btn-no-str btn-exit"
                            to="#"
                            onClick={() => {
                                setTimeout(() => {
                                    setIsShowModal(false);
                                    dispatch(setLogout());
                                }, 1);
                            }}
                        >
                            <img src="images/lk.png" height={17} />
                            <span>{translate("TR_EXIT")}</span>
                        </Link>
                    </div>
                </div>
            </div>
        }
        return <div className="dd-list" style={{ width: "300px" }}>
            <div className="login-form" style={{ display: isShowLoginModal ? "block" : "none" }}>
                <form onSubmit={userLogin}>
                    <input
                        className="w-100"
                        name="login"
                        placeholder={translate("TR_LOGIN")}
                        type="text"
                        value={loginFields.userName}
                        required
                        onChange={(event) => onChange(event, "userName", setLoginFields, 120)}
                    />
                    <input
                        className="w-100"
                        name="password"
                        placeholder={translate("TR_PASSWORD")}
                        type="password"
                        value={loginFields.password}
                        required
                        onChange={(event) => onChange(event, "password", setLoginFields, 120)}
                    />
                    <button
                        type="submit"
                        className={`btn btn-no-str btn-login w-100 ${isLoading ? "disabled" : ""}`}
                        style={{ display: "flex", cursor: "pointer" }}
                    >
                        {translate("TR_LOGIN_TO_CABINET")}
                    </button>
                    <center>
                        <Link
                            className={`go-to go-to-reg ${isLoading ? "disabled" : ""}`}
                            to="#"
                            onClick={() => setIsShowLoginModal(false)}
                        >
                            {translate("TR_REGISTRATION")}
                        </Link>
                    </center>
                </form>
            </div>

            <div className="reg-form" style={{ display: isShowLoginModal ? "none" : "block" }}>
                <Link
                    className={`back ${isLoading ? "disabled" : ""}`}
                    to="#"
                    onClick={() => setIsShowLoginModal(true)}
                >
                    {translate("TR_BACK")}
                </Link>
                <form onSubmit={userRegistration}>
                    <input className="w-100"
                        name="login"
                        placeholder={translate("TR_LOGIN")}
                        type="text"
                        value={registerFields.userName}
                        required
                        onChange={(event) => onChange(event, "userName", setRegisterFields, 120)}
                    />
                    <input className="w-100"
                        name="password"
                        placeholder={translate("TR_PASSWORD")}
                        type="password"
                        value={registerFields.password}
                        required
                        onChange={(event) => onChange(event, "password", setRegisterFields, 120)}
                    />
                    <input className="w-100"
                        name="email"
                        placeholder={translate("TR_EMAIL")}
                        type="text"
                        value={registerFields.email}
                        required
                        onChange={(event) => onChange(event, "email", setRegisterFields, 120)}
                    />
                    <button
                        type="submit"
                        className={`btn btn-no-str btn-reg w-100 ${isLoading ? "disabled" : ""}`}
                        style={{ display: "flex", cursor: "pointer" }}
                    >
                        {translate("TR_REGISTRATION")}
                    </button>
                </form>
            </div>




        </div>
    }


    return <div
        className="lk-icon animate"
        style={{ height: "27px", cursor: "pointer" }}
        onClick={() => setIsShowModal(true)}
    >
        {
            isShowModal ?
                <div className={`dd-list-cont d-block`}>
                    <div className="search-block-layer"
                        onClick={() => {
                            setTimeout(() => {
                                setIsShowModal(false)
                            }, 1);
                        }}
                    />
                    {setModal()}
                </div>
                : null
        }
    </div>


}

export default AuthComponent