import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import index from "./store";
import "./styles/styles.css";
import LangProvider from "./lang/LangProvider";
import TranslationProvider from "./lang/TranslationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={index}>
		<LangProvider>
			<TranslationProvider>
				<App />
			</TranslationProvider>
		</LangProvider>
	</Provider>
);
